import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import Organizations from "../../../api/organizations";
import { GlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import { authenticated, currentUserId, isUser } from "../../../lib/auth";
import { Account, Organization } from "../../../model";
import OrganizationBillingState from "../../../model/OrganizationBillingState";
import { OrganizationAccountsProvider } from "../../../providers";
import { environment } from "../../../util";

const FAILURE_TO_LOAD_ORGANIZATIONS_MESSAGE =
  "Unable to load owned organizations";

const CURRENT_ORG_KEY = "state.organization.current";

const GlobalOrganizationProvider = ({
  orgKey,
  children,
  organization,
  customTheme,
}: React.PropsWithChildren<{
  orgKey?: string;
  organization?: Organization;
  customTheme?: Partial<ThemeOptions>;
}>) => {
  // State used to hold information about possible organizations the user can switch to
  // and the organization they have selected
  const { handleRejectionWithError } = useAlert();
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >(organization);

  const [
    selectedOrganizationBillingState,
    setSelectedOrganizationBillingState,
  ] = useState<OrganizationBillingState | undefined>();

  // State to ensure that if the organizations are not yet loaded, we don't display the
  // warning banner but instead display a loading screen
  const organizationsLoaded = useRef<boolean>(false);
  const accountsLoaded = useRef<boolean>(false);
  const organizationsLoading = useRef<boolean>(false);
  const [accountListIsMemberOf, setAccountListIsMemberOf] = useState<
    Array<Account>
  >([]);

  // const isAdmin = useIsAdmin();
  const [organizationBillingStateLoaded, setOrganizationBillingStateLoaded] =
    useState<boolean>(false);

  const [organizations, setOrganizations] = useState<Organization[]>([]);

  /**
   * This method is to be used only in the case where an organization is updated on the frontend without a reload
   * - notification state raises a change in the organization
   * - manually refreshed by the end user for billing / subscription information
   */
  const refreshOrganization = useCallback(
    (organization_id: string) => {
      // if there is an issue, then we should return null
      // (the organization was not refreshed)
      if (!organization_id) return Promise.resolve(null);
      if (!authenticated() || !isUser()) {
        console.warn("no organizations loaded due to no user found");
        return Promise.resolve(null);
      }

      return Organizations.get(organization_id).then((organization) => {
        // replace the organization in the list
        const newOrganizations = organizations.map((org) =>
          org.id === organization_id ? organization : org,
        );
        setOrganizations(newOrganizations);
        return organization;
      });
    },
    [organizations],
  );

  const refreshOrganizations = useCallback(() => {
    if (!authenticated() || !isUser()) {
      console.warn("no organizations loaded due to no user found");
      return Promise.resolve([]);
    }
    organizationsLoading.current = true;

    return Organizations.byMember(currentUserId()).then(
      (organizations) => {
        organizationsLoaded.current = true;
        setOrganizations(organizations);
        organizationsLoading.current = false;
        return organizations;
      },
      (err) => {
        handleRejectionWithError(FAILURE_TO_LOAD_ORGANIZATIONS_MESSAGE);
        return err;
      },
    );
  }, [handleRejectionWithError]);

  // load the billing state for the selected organization
  useEffect(() => {
    setOrganizationBillingStateLoaded(false);
    if (!selectedOrganization) return;
    if (organizations.length == 0) return; // there are feasibly no organizations to load billing state for
    try {
      const organizationId = selectedOrganization.id;
      Organizations.getBillingState(organizationId).then(
        (organizationBillingState: OrganizationBillingState) => {
          setSelectedOrganizationBillingState(organizationBillingState);
          setOrganizationBillingStateLoaded(true);
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, [
    organizations.length,
    selectedOrganization,
    setOrganizationBillingStateLoaded,
  ]);

  const setAndStoreCurrentOrganization = useCallback(
    (organization?: Organization) => {
      setSelectedOrganization(organization);
      if (organization) {
        localStorage.setItem(CURRENT_ORG_KEY, organization.id);
      } else {
        localStorage.removeItem(CURRENT_ORG_KEY);
      }
    },
    [],
  );

  // Initial loading of all organizations
  useEffect(() => {
    if (!organizationsLoaded.current && !organizationsLoading.current) {
      // Convenience method for initializing the selected organization to the first one
      // in the list of all organizations if it exists
      const setCurrentOrganization = (organizations: Organization[]) => {
        if (organization) {
          setSelectedOrganization(organization);
          return;
        }
        if (
          selectedOrganization &&
          organizations.find((o) => o.id == selectedOrganization.id)
        ) {
          setSelectedOrganization(selectedOrganization);
          return;
        }
        const localSetting = localStorage.getItem(CURRENT_ORG_KEY);
        if (localSetting) {
          const lastOrganization = organizations.find(
            (o) => o.id === localSetting,
          );
          if (lastOrganization) {
            setSelectedOrganization(lastOrganization);
            return;
          }
        }

        if (organizations.length > 0) {
          setSelectedOrganization(organizations[0]);
          return;
        }

        setSelectedOrganization(undefined);
      };
      // 1. Load all organizations currently logged in user has access to administer
      refreshOrganizations().then((orgs) => {
        if (!orgKey && orgs && orgs.length > 0) {
          // 1. a) If this is not an enterprise organization site, set the first organization in list active
          setCurrentOrganization(orgs);
        }
      });
      if (orgKey) {
        // 2. If this is an enterprise organization site, load the specific organization
        Organizations.byKey(orgKey).then(
          (organization) => {
            setSelectedOrganization(organization);
          },
          () => {
            setSelectedOrganization(undefined);
          },
        );
      }
      if (authenticated() && isUser()) {
        OrganizationAccountsProvider.listAccountsIsMemberOf(
          currentUserId(),
        ).then((response) => {
          setAccountListIsMemberOf(response);
          accountsLoaded.current = true;
        });
      }
    }
  }, [orgKey, refreshOrganizations, selectedOrganization, organization]);

  const isMember = useCallback(
    (orgId?: string) => {
      return !!organizations.find((o) => orgId == o.id);
    },
    [organizations],
  );

  // Convenience method for fetching all the organizations the user owns
  // We allow consumers of this method to handle the error so that the types
  return (
    <GlobalOrganizationContext.Provider
      value={{
        organization: selectedOrganization,
        organizationBillingState: selectedOrganizationBillingState,
        organizationBillingStateLoaded: organizationBillingStateLoaded,
        organizations,
        organizationsLoaded: organizationsLoaded.current,
        accountsLoaded: accountsLoaded.current,
        setOrganization: setAndStoreCurrentOrganization,
        accountListIsMemberOf,
        setAccountListIsMemberOf,
        refreshOrganizations,
        refreshOrganization,
        isMember,
      }}
    >
      <ThemeProvider
        theme={(theme) => {
          const baseTheme = deepmerge(
            theme,
            localStorage.getItem("runway.theme") ?? environment.theme,
          );
          let themeOverride: Partial<ThemeOptions> = deepmerge(baseTheme, {
            palette: selectedOrganization?.theme?.palette ?? {},
          });
          if (customTheme) {
            themeOverride = deepmerge(themeOverride, customTheme);
          }
          return createTheme(themeOverride);
        }}
      >
        {children}
      </ThemeProvider>
    </GlobalOrganizationContext.Provider>
  );
};
export default GlobalOrganizationProvider;
