import grey from "@mui/material/colors/grey";
import { ThemeOptions, darken } from "@mui/material/styles";

const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      main: grey[600],
      dark: grey[600],
      light: grey[300],
    },
    action: {
      hover: "rgb(0,0,0,.08)",
      hoverOpacity: 0.08,
    },
    background: {
      drawer: grey[200],
      menu: grey[100],
      dark: grey[900],
      dark_transparent: "rgba(0,0,0,.6)",
    },
    progress: {
      progress: "rgb(38, 156, 136, 1)",
      remaining: "rgb(38, 156, 136, 0.6)",
      complete: "rgb(38, 156, 136, 1)",
    },
    testRow: {
      default: "#fff4e4",
      hover: darken("#fff4e4", 0.05),
      selected: "#ffeacc",
      selectedHover: darken("#ffeacc", 0.05),
    },
  },
  // transitions: {
  //   duration: {
  //     enteringScreen: 1500,
  //   },
  // },
  typography: {
    htmlFontSize: 16, // base font size used to calculate 1.0 REM
    h1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.4,
      letterSpacing: "0.46px",
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "1.8rem",
    },
    h4: {
      fontFamily: "Roboto",
      fontSize: "1.4rem",
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 15,
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "48px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "32px",
        },
      },
    },
  },
};

export default baseTheme;
