import { json } from "@codemirror/lang-json";
import { Info, Launch } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
import { FileAPI } from "../../../api";
import { UploadFileComponent } from "../../../components/core/UploadFile";
import DeleteOrganizationDialog from "../../../components/dialogs/DeleteOrganizationDialog";
import PATHS from "../../../components/navigation/_paths";
import useFeature from "../../../hooks/useFeature";
import useIsAdmin from "../../../hooks/useIsAdmin";
import keyify from "../../../lib/keyify";
import { useUpdateOrganizationProps } from "../components/UpdateOrganizationContext";

const OrganizationGeneralSettings: FC<unknown> = () => {
  const {
    organization: sourceOrganization,
    onError,
    onSubmit,
    disabled,
    buttonAlignment,
    buttonText,
    emailDisabled,
    onOrganizationDeleted,
    isCreatingOrganization,
  } = useUpdateOrganizationProps();

  const themeEditorFeatureFlag = useFeature("theme.organization.editor");
  const [loading, setLoading] = useState<boolean>(false);
  const canAdministrate = useIsAdmin();
  const navigate = useNavigate();

  if (!canAdministrate && !isCreatingOrganization) {
    navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
  }

  // State used for the organization deletion dialog
  const [
    confirmOrganizationDeleteDialogOpen,
    setConfirmDeleteOrganizationDialogOpen,
  ] = useState<boolean>(false);
  const [organization, setOrganization] = useState(sourceOrganization);

  // State to hold the organization logo
  const [logo, setLogo] = useState<string | undefined>(
    sourceOrganization.theme?.shortLogoUrl,
  );

  // State to hold the organization's long logo
  const [longLogo, setLongLogo] = useState<string | undefined>(
    sourceOrganization.theme?.longLogoUrl,
  );

  // State to hold the organization's favicon
  const [favicon, setFavicon] = useState<string | undefined>(
    sourceOrganization.theme?.faviconUrl,
  );

  // State to keep track of when a key is manually changed by the user overriding the generated value
  const [keyEntered, setKeyEntered] = useState(false);

  // State to hold error for data confirmation
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setOrganization(sourceOrganization);
    setLogo(sourceOrganization.theme?.shortLogoUrl);
    setLongLogo(sourceOrganization.theme?.longLogoUrl);
    setFavicon(sourceOrganization.theme?.faviconUrl);
  }, [sourceOrganization]);

  const handleSubmit = async () => {
    if (!organization.name) {
      setError("Organization name is required");
      if (onError) {
        onError("Organization name is required");
      }
      return;
    }

    setLoading(true);
    try {
      await onSubmit({
        ...organization,
        id: organization.id ?? uuid(),
        owner_id: organization.owner_id ?? uuid(),
        key: organization.key ?? keyify(organization.name),
        theme: {
          ...organization.theme,
          shortLogoUrl: logo,
          longLogoUrl: longLogo,
          faviconUrl: favicon,
        },
      });

      setLoading(false);
      setError(undefined);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err);
      setLoading(false);
      setError(`Failure: ${err.message}`);
      if (onError) {
        onError(err);
      }
    }
  };

  const handleFileUpload = async (
    files: FileList | null,
  ): Promise<string | undefined> => {
    if (!files) {
      return;
    }

    try {
      const value = await FileAPI.uploadFile(files);

      // replacing '/' in keys so they can be used to fetch files
      const keyedFiles = value.map((fileUploadResponse) => {
        return `${process.env.REACT_APP_REST_API}/files/${encodeURIComponent(
          fileUploadResponse.key,
        )}`;
      });

      return keyedFiles[0];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // upload failed
      console.error(`Error uploading file: ${err}`);
      let error = "Error uploading image";
      if (err.response.status === 413) {
        error = "Image is too large to upload";
      }
      onError?.(error);
      setError(error);
      return undefined;
    }
  };

  const handleUploadFavicon = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files).then((url) => setFavicon(url));
  };

  const handleUploadShortLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files).then((url) => setLogo(url));
  };

  const handleUploadPrimaryLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files).then((url) => setLongLogo(url));
  };

  if (organization)
    return (
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h2">Organization</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Publicly accessible link to public portal for this organization">
              <Chip
                label="Portal"
                deleteIcon={<Launch />}
                onDelete={() => {
                  /* */
                }}
                target="_blank"
                component="a"
                href={`/${organization.key}`}
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={"Name"}
            value={organization.name}
            disabled={disabled}
            onChange={(e) => {
              setOrganization((org) => ({
                ...org,
                name: e.target.value,
                key: keyEntered ? organization.key : keyify(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={"Organization Handle"}
            value={organization.key}
            disabled={disabled}
            onChange={(e) => {
              setKeyEntered(true);
              setOrganization((org) => ({ ...org, key: e.target.value }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="A unique, human-readable handle for your organization.  This will be used to create your unique URL. The handle cannot be changed.">
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: !!organization?.id,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Description"}
            size="small"
            disabled={disabled}
            value={organization.description ?? ""}
            onChange={(e) =>
              setOrganization((org) => ({
                ...org,
                description: e.target.value,
              }))
            }
          />
        </Grid>
        {!emailDisabled && (
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label={"Email"}
              size="small"
              disabled={disabled}
              value={organization.email ?? ""}
              onChange={(e) =>
                setOrganization((org) => ({ ...org, email: e.target.value }))
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Billing Email"}
            size="small"
            disabled={!isCreatingOrganization}
            value={organization.billing_email ?? ""}
            onChange={(e) =>
              setOrganization((org) => ({
                ...org,
                billing_email: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2">Brand</Typography>
        </Grid>
        <Grid item sm={4}>
          <Box sx={{ flex: 1, maxWidth: "180px" }}>
            <Typography variant="subtitle1">Favicon</Typography>
            <UploadFileComponent
              renderer={(file) => {
                return file ? <img src={file} alt="favicon" /> : undefined;
              }}
              disabled={disabled}
              files={[favicon]}
              handleDrop={(e) => {
                if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
                  handleFileUpload(e.dataTransfer?.files).then((url) =>
                    setFavicon(url),
                  );
                }
              }}
              handleFileChange={(e) => {
                handleUploadFavicon(e);
              }}
              multiple={false}
              accept="image/*"
              uploadText="Upload"
              onRemove={() => {
                setFavicon(undefined);
              }}
              size="small"
            />
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box sx={{ flex: 1, maxWidth: "180px" }}>
            <Typography variant="subtitle1">Short Logo</Typography>
            <UploadFileComponent
              renderer={(file) => {
                return file ? <img src={file} alt="logo" /> : undefined;
              }}
              disabled={disabled}
              files={[logo]}
              handleDrop={(e) => {
                if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
                  handleFileUpload(e.dataTransfer?.files).then((url) =>
                    setLogo(url),
                  );
                }
              }}
              handleFileChange={(e) => {
                handleUploadShortLogo(e);
              }}
              multiple={false}
              accept="image/*"
              uploadText="Upload"
              onRemove={() => {
                setLogo(undefined);
              }}
              size="small"
            />
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box sx={{ flex: 1, maxWidth: "180px" }}>
            <Typography variant="subtitle1">Primary Logo</Typography>
            <UploadFileComponent
              renderer={(file) => {
                return file ? <img src={file} alt="long logo" /> : undefined;
              }}
              disabled={disabled}
              files={[longLogo]}
              handleDrop={(e) => {
                if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
                  handleFileUpload(e.dataTransfer?.files).then((url) =>
                    setLongLogo(url),
                  );
                }
              }}
              handleFileChange={(e) => {
                handleUploadPrimaryLogo(e);
              }}
              multiple={false}
              accept="image/*"
              uploadText="Upload"
              onRemove={() => {
                setLongLogo(undefined);
              }}
              size="small"
            />
            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: buttonAlignment === "right" ? "right" : "left",
              }}
            >
              <Button
                variant="outlined"
                disabled={disabled}
                onClick={() => setLongLogo(logo)}
              >
                Use Short Logo
              </Button>
            </Box>
          </Box>
        </Grid>
        {themeEditorFeatureFlag && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">Configuration</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Theme</Typography>
              <CodeMirror
                value={JSON.stringify(organization.theme, null, "  ") ?? ""}
                height="200px"
                extensions={[json()]}
                aria-disabled={disabled}
                onChange={(value) => {
                  try {
                    const parsed = JSON.parse(value);
                    setOrganization((org) => ({ ...org, theme: parsed }));
                  } catch (e) {
                    // do nothing
                    console.error("invalid JSON!", e);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Config</Typography>
              <CodeMirror
                value={JSON.stringify(organization.config, null, "  ") ?? ""}
                height="200px"
                extensions={[json()]}
                aria-disabled={disabled}
                onChange={(value) => {
                  try {
                    const parsed = JSON.parse(value);
                    setOrganization((org) => ({ ...org, config: parsed }));
                  } catch (e) {
                    // do nothing
                    console.error("invalid JSON!", e);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Well Known Processes</Typography>
              <CodeMirror
                value={
                  JSON.stringify(
                    organization.well_known_processes,
                    null,
                    "  ",
                  ) ?? ""
                }
                height="200px"
                extensions={[json()]}
                aria-disabled={disabled}
                onChange={(value) => {
                  try {
                    const parsed = JSON.parse(value);
                    setOrganization((org) => ({
                      ...org,
                      well_known_processes: parsed,
                    }));
                  } catch (e) {
                    // do nothing
                    console.error("invalid JSON!", e);
                  }
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              my: 4,
              display: "flex",
              justifyContent: buttonAlignment === "right" ? "right" : "left",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleSubmit}
              >
                {buttonText ?? "Submit"}
              </Button>
            )}
          </Box>
        </Grid>
        {error && (
          <Box sx={{ my: 2 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
        {onOrganizationDeleted && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">Danger Zone</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  my: 4,
                  display: "flex",
                  justifyContent:
                    buttonAlignment === "right" ? "right" : "left",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setConfirmDeleteOrganizationDialogOpen(true)}
                >
                  Delete Organization
                </Button>
              </Box>
            </Grid>
            <DeleteOrganizationDialog
              open={confirmOrganizationDeleteDialogOpen}
              organization={organization}
              setDialogOpen={setConfirmDeleteOrganizationDialogOpen}
              onOrganizationDeleted={onOrganizationDeleted}
            />
          </>
        )}
      </Grid>
    );

  /*  return (
      <>
        <Grid item xs={8}>
          <Typography variant="h2">Organization</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Publicly accessible link to public portal for this organization">
              <Chip
                label="Portal"
                deleteIcon={<Launch />}
                onDelete={() => {
                }}
                target="_blank"
                component="a"
                href={`/${organization.key}`}
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={"Name"}
            value={organization.name}
            disabled={disabled}
            onChange={(e) => {
              setOrganization((org: Organization) => ({
                ...org,
                name: e.target.value,
                key: keyEntered ? organization.key : keyify(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={"Organization Handle"}
            value={organization.key}
            disabled={disabled}
            onChange={(e) => {
              setKeyEntered(true);
              setOrganization((org) => ({ ...org, key: e.target.value }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="A unique, human-readable handle for your organization.  This will be used to create your unique URL. The handle cannot be changed.">
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: !!organization?.id,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Description"}
            size="small"
            disabled={disabled}
            value={organization.description ?? ""}
            onChange={(e) =>
              setOrganization(
                (org) =>
                  ({
                    ...org,
                    description: e.target.value,
                  }) as Organization,
              )
            }
          />
        </Grid>
        {!emailDisabled && (
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label={"Email"}
              size="small"
              disabled={disabled}
              value={organization.email ?? ""}
              onChange={(e) =>
                setOrganization((org) => ({ ...org, email: e.target.value }))
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Billing Email"}
            size="small"
            disabled={!isCreatingOrganization}
            value={organization.billing_email ?? ""}
            onChange={(e) =>
              setOrganization(
                (org) =>
                  ({
                    ...org,
                    billing_email: e.target.value,
                  }) as Organization,
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              my: 4,
              display: "flex",
              justifyContent: buttonAlignment === "right" ? "right" : "left",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleSubmit}
              >
                {buttonText ?? "Submit"}
              </Button>
            )}
          </Box>
        </Grid>
        {error && (
          <Box sx={{ my: 2 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
      </>
    );*/
};

export { OrganizationGeneralSettings };
