import { APIProvider } from "@vis.gl/react-google-maps";
import { FC, useEffect, useRef } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { useAlert } from "../../lib/alert";
import eventEmitter from "../../lib/event";
import {
  createWebSocketMessage,
  setupWebSocket,
} from "../../lib/websocket/websocket";
import {
  WebSocketMessageType,
  WebSocketTinyNotificationData,
} from "../../model/WebSocketMessage";
import RunwayThemeProvider from "../../providers/theme/RunwayThemeProvider";
import AppRoutes from "../../routes";
import environment from "../../util/environment";

const Router = BrowserRouter; // far future -> may need to use MemoryRouter for embedded components

const messages = { ...(environment.theme ? environment.theme.lang : {}) };

const AppShell: FC<unknown> = () => {
  const { success, error, info, warning } = useAlert();

  const ws = useRef<WebSocket | undefined>(undefined);

  eventEmitter.on(
    "websocket:tinyNotification",
    (data: WebSocketTinyNotificationData) => {
      if (data.type === "ERROR") error(data.message);
      if (data.type === "SUCCESS") success(data.message);
      if (data.type === "INFO") info(data.message);
      if (data.type === "WARNING") warning(data.message);
    },
  );

  // connect to websocket on mount and user identity change
  useEffect(() => {
    // default is "ws://${domain-and-port}/wss"
    ws.current = new WebSocket(
      process.env.REACT_APP_WEBSOCKET_URL ??
        `wss://${window.location.host}/wss`,
    );
    ws.current.onopen = () => {
      // identify the user on the server to begin with
      ws.current?.send(
        JSON.stringify(
          createWebSocketMessage(WebSocketMessageType.IDENTITY, {}),
        ),
      );
      if (ws.current) setupWebSocket(ws.current);
    };
    return () => {
      ws.current?.close();
    };
  }, [success]);

  return (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      <RunwayThemeProvider>
        <APIProvider
          libraries={["places"]}
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY || ""}
        >
          <Router>
            <AppRoutes />
          </Router>
        </APIProvider>
      </RunwayThemeProvider>
    </IntlProvider>
  );
};

export default AppShell;
