import { MoreHoriz } from "@mui/icons-material";
import {
  Badge,
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { NotificationAPI } from "../../../api";
import { HumanReadableTimestamp } from "../../../components";
import PATHS from "../../../components/navigation/_paths";
import { useAlert } from "../../../lib/alert";
import Notification, {
  NotificationStatus,
  NotificationType,
} from "../../../model/Notification";

const CLICK_ACTIONS: {
  [key: string]: (
    notification: Notification,
    navigate: NavigateFunction,
  ) => boolean;
} = {
  [NotificationType.TASK]: (
    notification: Notification,
    navigate: NavigateFunction,
  ) => {
    // subject_id is the process execution id
    // data should contain the process id

    const processId = notification.data?.process_id as string;
    const processExecutionId = notification.data
      ?.process_execution_id as string;

    if (!processId) return false;
    if (!processExecutionId) return false;

    navigate(PATHS.EXECUTION.linkTo(processId, processExecutionId));

    // navigate to the process execution

    return true;
  },
};

const NotificationMenuItem = ({
  notification,
  onClick,
  onDelete,
  onChangeStatus,
}: {
  notification: Notification;
  onClick?: () => void;
  onDelete?: () => void;
  onChangeStatus?: (status: NotificationStatus) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const { error } = useAlert();

  const markNotificationStatus = useCallback(
    async (status: NotificationStatus) => {
      await NotificationAPI.markNotificationStatus(notification.id, status);
    },
    [],
  );

  const [loading, setLoading] = useState(false);

  const handleNotificationClick = useCallback(async () => {
    setLoading(true);
    onClick && onClick();

    const action = CLICK_ACTIONS[notification.type.toString()];
    if (action) {
      onChangeStatus && onChangeStatus(NotificationStatus.READ);
      await markNotificationStatus(NotificationStatus.READ);
      const handled = action(notification, navigate);
      if (!handled) {
        error("Could not handle notification");
      }
    }
    setLoading(false);
  }, [
    error,
    markNotificationStatus,
    navigate,
    notification,
    onChangeStatus,
    onClick,
  ]);

  return (
    <ButtonBase
      onClick={() => handleNotificationClick()}
      sx={{
        width: "100%",
      }}
    >
      <Box
        p={1}
        my={0.5}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"left"}
        width={"100%"}
        ml={1}
      >
        <Box flex={1} mr={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"}>
              {notification.status == NotificationStatus.UNREAD && (
                <Badge
                  color="error"
                  variant="dot"
                  sx={{
                    mr: 1.5,
                    ml: 0.5,
                  }}
                />
              )}
              <Typography
                variant="body1"
                fontWeight={"bold"}
                textOverflow={"ellipsis"}
              >
                {notification.title}
              </Typography>
            </Box>

            {notification.created_at && (
              <HumanReadableTimestamp
                variant="caption"
                timestamp={notification.created_at}
              />
            )}
          </Box>

          <Typography variant="body2" fontSize={12}>
            {notification.body}
          </Typography>
        </Box>
        {loading ? (
          <CircularProgress
            size={20}
            sx={{
              mr: 1,
              color: (theme) => theme.palette.background.paper,
            }}
          />
        ) : (
          <>
            <IconButton
              size="small"
              sx={{
                color: (theme) => theme.palette.background.paper,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setMenuOpen(true);
              }}
            >
              <MoreHoriz />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              {notification.status == NotificationStatus.UNREAD && (
                <MenuItem
                  onClick={async (e) => {
                    // notification.markAsRead();
                    e.stopPropagation();
                    onChangeStatus && onChangeStatus(NotificationStatus.READ);
                    await markNotificationStatus(NotificationStatus.READ);
                    setMenuOpen(false);
                  }}
                >
                  Mark as Read
                </MenuItem>
              )}
              {notification.status == NotificationStatus.READ && (
                <MenuItem
                  onClick={async (e) => {
                    // notification.markAsUnread();
                    e.stopPropagation();
                    onChangeStatus && onChangeStatus(NotificationStatus.UNREAD);
                    await markNotificationStatus(NotificationStatus.UNREAD);
                    setMenuOpen(false);
                  }}
                >
                  Mark as Unread
                </MenuItem>
              )}
              <MenuItem
                onClick={async (e) => {
                  e.stopPropagation();
                  // notification.markAsRead();
                  onDelete && onDelete();
                  await markNotificationStatus(NotificationStatus.REMOVED);
                  setMenuOpen(false);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
    </ButtonBase>
  );
};

export default NotificationMenuItem;
