import { NotificationAPI } from "../api";
import { NotificationStatus } from "../model/Notification";
import eventEmitter from "./event";

export class NotificationState {
  public static notifications: Notification[] = [];
}
/**
 * Requests notification permission if it is not already granted
 * Needs to be called only once
 * Should be called on the first user interaction (best practice)
 */
export const requestNotificationPermissionIfRequired = async () => {
  if (Notification.permission !== "granted") {
    await Notification.requestPermission();
  }
};

/**
 * Cancels a notification by its id
 * Different platforms may have different behavior
 */
export const cancelNotification = (notificationId: string) => {
  const notification = NotificationState.notifications.find(
    (n) => n.tag === notificationId,
  );
  if (notification) {
    notification.close();
  }
};

/**
 * Gets the current notification permission
 * Possible values: "default", "granted", "denied"
 * Could be updated by requestNotificationPermissionIfRequired
 */
export const getNotificationPermission = () => {
  return Notification.permission;
};

/**
 * Helper method to show a notification using the browser's native notification system
 * Returns the notification object
 * Tag being "id" allows for the notification to stack with others of the same id
 */
export const showNotification = (
  title: string,
  body: string,
  notification_id: string,
  icon?: string,
  onClick?: (notification: Notification, event: Event) => void,
  onShow?: (notification: Notification, event: Event) => void,
  onClose?: (notification: Notification, event: Event) => void,
) => {
  const notificationToShow = new Notification(title, {
    body,
    icon,
    badge: icon,
    tag: notification_id,
    data: {
      id: notification_id,
    },
    requireInteraction: true,
  });
  notificationToShow.onclick = onClick
    ? (event) => {
        onClick(notificationToShow, event);
        NotificationAPI.markNotificationStatus(
          notification_id,
          NotificationStatus.READ,
        );
        eventEmitter.emit("notification:statusChange:read", notification_id);
      }
    : null;
  notificationToShow.onshow = onShow
    ? (event) => {
        onShow(notificationToShow, event);
      }
    : null;
  notificationToShow.onclose = onClose
    ? (event) => {
        onClose(notificationToShow, event);
      }
    : null;

  NotificationState.notifications.push(notificationToShow);
  return notificationToShow;
};
