import {
  CheckCircleOutline,
  Draw,
  ErrorOutline,
  HourglassBottom,
  Send,
  Visibility,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Collapse,
  Divider,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProcessExecutionAPI } from "../../../../api";
import eventEmitter from "../../../../lib/event";
import {
  NotificationEventType,
  ProcessExecutionTracking,
} from "../../../../model/NotificationEvent";
import { ProcessExecution } from "../../../../model/Process";
import { TaskExecutionTrackerStage } from "./TaskExecutionTrackerStage";

export type ProgressBarStage = {
  label: (triggered: boolean, error?: boolean) => string;
  progress: number;
  tooltip: (triggered: boolean) => string;
  errorMessage?: string;
  icon?: JSX.Element;
  largeIcon?: JSX.Element;
};

// Map of error triggers to the event type they should be associated with
// this will show the associated variable in the tracker
const errorTriggers: { [key: string]: NotificationEventType } = {
  Bounce: NotificationEventType.Delivery,
};

const stages: { [key: string]: ProgressBarStage } = {
  Delivery: {
    progress: 0,
    label: (triggered: boolean, error?: boolean) => {
      if (error) return "Delivery Error";
      return triggered ? "Delivered" : "Not Delivered";
    },
    tooltip: (triggered: boolean) =>
      triggered
        ? "A message has been delivered to the assigned user"
        : "A message has not been delivered to the assigned user",
    errorMessage: "Error delivering message to one or more assigned users",
    icon: (
      <Send
        sx={{
          fontSize: 16,
        }}
      />
    ),
    largeIcon: (
      <Send
        sx={{
          fontSize: 40,
        }}
      />
    ),
  },
  SeenAssigned: {
    progress: 25,
    label: (triggered: boolean) => (triggered ? "Viewed" : "Not Seen"),

    tooltip: (triggered: boolean) =>
      triggered
        ? "The user has viewed the task"
        : "The user has not viewed the task",
    icon: (
      <Visibility
        sx={{
          fontSize: 16,
        }}
      />
    ),
    largeIcon: (
      <Visibility
        sx={{
          fontSize: 40,
        }}
      />
    ),
  },
  Progress: {
    progress: 60,
    label: (triggered: boolean) => (triggered ? "Edited" : "Waiting"),
    tooltip: (triggered: boolean) =>
      triggered
        ? "The user has modified the task"
        : "The user has not modified the task yet",
    icon: (
      <Draw
        sx={{
          fontSize: 16,
        }}
      />
    ),
    largeIcon: (
      <Draw
        sx={{
          fontSize: 40,
        }}
      />
    ),
  },
  Completed: {
    progress: 100,
    label: (triggered: boolean) => (triggered ? "Completed" : "Not Done"),
    tooltip: (triggered: boolean) =>
      triggered
        ? "The user has completed the process"
        : "The user has not completed the process",
    errorMessage: "Error completing the process",
    icon: (
      <CheckCircleOutline
        sx={{
          fontSize: 16,
        }}
      />
    ),
    largeIcon: (
      <CheckCircleOutline
        sx={{
          fontSize: 40,
        }}
      />
    ),
  },
};

export const TaskExecutionTracker = ({
  processExecution,
  taskId,
  onCompleted,
}: {
  processExecution?: ProcessExecution;
  taskId?: string;
  onCompleted?: () => void;
}) => {
  const [trackerEvents, setTrackerEvents] = useState<
    ProcessExecutionTracking[]
  >([]);

  useEffect(() => {
    setTrackerEvents([]);
    setTrackingError(null);
    setLastViewingNotice(undefined);
  }, [processExecution]);

  const [currentTrackerProgress, setCurrentTrackerProgress] = useState(0);
  const [lastViewingNotice, setLastViewingNotice] = useState<Date | undefined>(
    undefined,
  );
  const [viewingSince, setViewingSince] = useState<Date | undefined>(undefined);
  const [trackingError, setTrackingError] =
    useState<NotificationEventType | null>(null);

  const showError = useMemo(() => {
    // if "Completed" is in the tracker events, then we don't need to show the error
    if (trackerEvents.find((tracker) => tracker.state == "Completed"))
      return false;

    return trackingError != null;
  }, [trackingError, trackerEvents]);

  const isCompleted = useMemo(() => {
    return trackerEvents.find((tracker) => tracker.state == "Completed");
  }, [trackerEvents]);

  const getProgressValue = (state: string) => {
    switch (state) {
      case "SeenAssigned":
        return 29;
      case "Progress":
        return 64;
      case "Completed":
        return 102;
      default:
        return 0;
    }
  };

  const getTrackerEvent = useCallback(
    (state: string) => {
      const notificationEvent = state as NotificationEventType;
      const found = trackerEvents.find(
        (tracker) => tracker.state == notificationEvent,
      );
      return found;
    },
    [trackerEvents],
  );

  const addTrackerEvent = useCallback(
    (event: ProcessExecutionTracking): boolean => {
      // add if type is not already in the list
      // otherwise, update

      const existing = trackerEvents.find(
        (tracker) => tracker.state == event.state,
      );
      if (existing) {
        return false;
      } else {
        const associatedError = errorTriggers[event.state];
        if (associatedError) {
          // then this notification is an error trigger
          setTrackingError(associatedError);
        }

        setTrackerEvents([...trackerEvents, event]);
      }

      return true;
    },
    [trackerEvents],
  );

  useEffect(() => {
    if (!taskId) return;
    if (!processExecution) return;

    ProcessExecutionAPI.getTaskTrackingInfo(
      processExecution?.id as string,
      taskId as string,
    ).then((tracking) => {
      let largestProgress: number = 0;
      for (const track of tracking) {
        const processExecutionTracking = {
          timestamp: new Date(track.timestamp),
          state: track.eventType,
        };
        addTrackerEvent(processExecutionTracking);
        const progress = getProgressValue(track.eventType);
        if (progress > largestProgress) {
          largestProgress = progress;
        }
      }
      setCurrentTrackerProgress(largestProgress);
    });
  }, [addTrackerEvent, processExecution, taskId]);

  eventEmitter.on("websocket:tracking", (data) => {
    if (
      data.process_execution_id === processExecution?.id &&
      data.task_id === taskId
    ) {
      const state = data.state as NotificationEventType;
      if (!state) return;

      const processExecutionTracking = {
        timestamp: new Date(),
        state: state,
      };
      const result = addTrackerEvent(processExecutionTracking);

      if (!result) return;

      const progress = getProgressValue(state);
      if (progress > currentTrackerProgress) {
        setCurrentTrackerProgress(progress);
      }

      if (state == NotificationEventType.Completed) {
        onCompleted && onCompleted();
      }
    }
  });

  eventEmitter.on("websocket:currentlyViewing", (data) => {
    // the server has determined that it's from an important source...
    if (data.process_execution_id === processExecution?.id) {
      setViewingSince(new Date());
      setLastViewingNotice(new Date());
    }
  });

  // checker every 5 seconds to see if the user is still viewing
  useEffect(() => {
    const interval = setInterval(() => {
      if (lastViewingNotice) {
        const diff = new Date().getTime() - lastViewingNotice.getTime();
        if (diff > 5000) {
          setLastViewingNotice(undefined);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  const isSmallTracker = useMediaQuery("(max-width:1500px)");

  return (
    <>
      <Box sx={{ padding: 1 }}>
        <Box display={"flex"} alignItems={"center"}>
          {!isCompleted ? <HourglassBottom /> : <CheckCircleOutline />}
          <Typography variant="h6" fontWeight="bold" sx={{ marginLeft: 1 }}>
            {isCompleted
              ? "This Task Has Been Completed"
              : "This Task is in Progress"}
          </Typography>
        </Box>

        <Collapse in={isCompleted ? false : true}>
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <Box>
              <Typography variant="body2" fontWeight="bold">
                Last Updated:
              </Typography>
              <Typography variant="body2">Actively viewing</Typography>
            </Box>
            <Box>
              <Box
                display={"flex"}
                justifyContent={"right"}
                alignItems={"center"}
              >
                <Badge
                  variant="dot"
                  color={lastViewingNotice ? "primary" : "default"}
                  sx={{
                    marginRight: 1.5,
                  }}
                ></Badge>
                <Typography variant="body2" fontWeight="bold" textAlign="right">
                  Current Status
                </Typography>
              </Box>

              <Typography variant="body2" textAlign="right">
                {lastViewingNotice && viewingSince
                  ? "Viewing since " + viewingSince?.toLocaleTimeString()
                  : viewingSince
                    ? "Last viewed at " + viewingSince?.toLocaleTimeString()
                    : "Not viewing"}
              </Typography>
            </Box>
          </Box>
        </Collapse>

        {!isSmallTracker ? (
          <Box
            sx={{
              position: "relative",
              height: 60,
              marginTop: 3,
              marginBottom: 4,
            }}
          >
            {/* <Box
              sx={(theme) => ({
                position: "absolute",
                top: currentlyViewing ? -24 : 0,
                left: "5%",
                backgroundColor: showError
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
                paddingTop: 0,
                paddingRight: 1,
                paddingLeft: 1,
                // make the top left and bottom right corners rounded
                borderRadius: "4px 4px 0px 0px",
                transition: "0.5s ease-in-out",
              })}
            >
              <Typography variant="caption" color={"white"} fontWeight="bold">
                Currently Viewing
              </Typography>
            </Box> */}
            <LinearProgress
              variant="determinate"
              color={showError ? "error" : "primary"}
              sx={{
                height: 24,
                borderRadius: 5,
                position: "relative",
                "& .MuiLinearProgress-bar": {
                  transition: "1s ease-in-out",
                  borderRadius: 5,
                },
              }}
              value={currentTrackerProgress - 2}
            />
            {Object.entries(stages).map((stage) => (
              <TaskExecutionTrackerStage
                progressBarStage={stage[1]}
                isError={trackingError == stage[0]}
                isTriggered={getTrackerEvent(stage[0]) != null}
                trigger={getTrackerEvent(stage[0])}
                isEntireError={showError}
                key={stage[0]}
                trackerEventsContext={trackerEvents}
                variant="progress"
              />
            ))}
          </Box>
        ) : (
          <Box width={"100%"} display={"block"}>
            <Divider
              sx={{
                my: 2,
              }}
            />
            {Object.entries(stages).map((stage) => (
              <TaskExecutionTrackerStage
                progressBarStage={stage[1]}
                isError={trackingError == stage[0]}
                isTriggered={getTrackerEvent(stage[0]) != null}
                trigger={getTrackerEvent(stage[0])}
                isEntireError={showError}
                key={stage[0]}
                trackerEventsContext={trackerEvents}
                variant="list"
              />
            ))}
          </Box>
        )}

        {showError && (
          <Box
            flex={1}
            display={"flex"}
            justifyContent={"center"}
            padding={1}
            alignItems={"center"}
          >
            <ErrorOutline color="error" />
            <Typography
              variant="body2"
              color="error"
              fontWeight={"bold"}
              marginLeft={1}
            >
              {trackingError ? stages[trackingError].errorMessage : ""}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
