import {
  ArrowDropDown,
  ArrowDropUp,
  ExpandLess,
  ExpandMore,
  Logout,
  PeopleOutline,
  Person,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import { useAtom } from "jotai";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountTypeAPI, UserAPI } from "../../api";
import PATHS from "../../components/navigation/_paths";
import useFeature from "../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import useIsAdmin from "../../hooks/useIsAdmin";
import { useAlert } from "../../lib/alert";
import { currentUserId } from "../../lib/auth";
import { AccountType } from "../../model/AccountType";
import { CreateAccountTypeDialog } from "../accounts";
import LeaveOrganizationDialog from "../user/LeaveOrganizationDialog";
import OrganizationLogo from "./components/OrganizationLogo";
import { accountTypesAtom } from "./state/accountTypesAtom";

const MEMBERSHIP_LINKS = [PATHS.MEMBERS];

type NavLinkProps = React.PropsWithChildren<{
  dataCy?: string;
  title: string;
  titleIntl?: JSX.Element;
  link?: string;
  onClick?: () => void;
  icon?: JSX.Element;
  sx?: SxProps<Theme>;
  selectedOn?: LinkSelectedOn;
}>;

enum LinkSelectedOn {
  MATCH,
  START,
}

const NavLink = ({
  dataCy,
  title,
  titleIntl,
  link,
  icon,
  children,
  sx,
  onClick,
  selectedOn = LinkSelectedOn.START,
}: NavLinkProps) => {
  const { pathname } = useLocation();
  const linkProps = link
    ? {
        to: link,
        component: Link,
        selected:
          selectedOn === LinkSelectedOn.START
            ? pathname.startsWith(link)
            : pathname === link,
      }
    : {};
  return (
    <ListItemButton
      data-cy={dataCy}
      key={title}
      component={Link}
      onClick={onClick}
      sx={sx}
      {...linkProps}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={titleIntl ?? title}
        sx={{
          "& .MuiTypography-root": { fontWeight: 600, lineHeight: "24px" },
        }}
      />
      {children}
    </ListItemButton>
  );
};

const OrganizationDrawerMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { organization, organizationsLoaded, refreshOrganizations } =
    useGlobalOrganizationContext();
  const { handleRejectionWithError } = useAlert();
  const [accountTypesOpen, setAccountTypesOpen] = useState(true);
  const [accountTypes, setAccountTypes] = useAtom(accountTypesAtom);
  //const isEnterprise = !!environment.organization?.key;
  const canViewDashboard = useFeature("dashboard");
  const canManageRoles = useFeature("roles.administration");

  const settingLinks = useMemo(() => {
    const availableSettingsLinks = [];
    availableSettingsLinks.push(PATHS.PROCESSES);
    availableSettingsLinks.push(PATHS.MEMBERS);
    if (canManageRoles) {
      availableSettingsLinks.push(PATHS.ROLES);
    }
    availableSettingsLinks.push(PATHS.EMAIL_FILTERS);
    availableSettingsLinks.push(PATHS.ORGANIZATION_MANAGE);
    return availableSettingsLinks;
  }, [canManageRoles]);

  const isSettingsRoute = settingLinks.some((sub) => sub.link === pathname);
  const [administrationOpen, setAdministrationOpen] = useState(isSettingsRoute);
  const [membershipOpen, setMembershipOpen] = useState(false);
  const [createAccountTypeDialogOpen, setCreateAccountTypeDialogOpen] =
    useState(false);
  const [leaveOrganizationDialogOpen, setLeaveOrganizationDialogOpen] =
    useState(false);

  const canManageAccountTypes = useFeature("accountType.administration");

  const { success, error } = useAlert();

  const canAdministrate = useIsAdmin();

  const canLeave = useMemo(() => {
    return organization?.owner_id !== currentUserId();
  }, [organization?.owner_id]);

  useEffect(() => {
    if (organization) {
      AccountTypeAPI.byOrganizationId(organization?.id).then(
        (accountTypes: Array<AccountType>) => {
          setAccountTypes(accountTypes);
        },
        handleRejectionWithError,
      );
    }
  }, [organization, handleRejectionWithError, setAccountTypes]);

  const leaveOrganizationHandle = useCallback(() => {
    if (!organization) return;
    UserAPI.leaveOrganization(currentUserId(), organization.id)
      .then(() => {
        success(`You have successfully left ${organization.name}.`);

        // navigate to manage organizations page
        navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link);

        refreshOrganizations();
      })
      .catch(() => {
        error("An error occured while trying to leave this organization.");
      });
  }, [error, navigate, organization, refreshOrganizations, success]);

  if (!organizationsLoaded) {
    return <></>;
  }
  return (
    <Box
      data-cy={"side-drawer"}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "240px",
        height: "100%",
        displayPrint: "none",
        overflowY: "auto",
        px: 2,
        py: 1,
      }}
    >
      <OrganizationLogo
        organization={organization}
        onClick={() => {
          navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
        }}
      />
      <List
        component="nav"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 0.5,
          "& .MuiListItemButton-root": {
            borderRadius: 2,
          },
        }}
      >
        {canViewDashboard && (
          <NavLink sx={{ flex: 0 }} {...PATHS.DASHBOARD}></NavLink>
        )}
        <NavLink
          dataCy="organization-executions-btn"
          sx={{ flex: 0 }}
          onClick={() => {
            setAdministrationOpen(false);
          }}
          {...PATHS.ORGANIZATION_EXECUTIONS}
        ></NavLink>
        {canManageAccountTypes && (
          <>
            <NavLink
              dataCy="accounts-btn"
              sx={{ flex: 0 }}
              {...PATHS.ACCOUNTS}
              selectedOn={LinkSelectedOn.MATCH}
            >
              <IconButton
                onClick={(e) => {
                  setAccountTypesOpen((open) => !open);
                  e.preventDefault();
                }}
                sx={{ p: 0 }}
              >
                {accountTypesOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </NavLink>
            <Collapse in={accountTypesOpen}>
              <List component="div" disablePadding>
                {accountTypes?.map((accountType) => (
                  <ListItemButton
                    data-cy="account-type-btn"
                    component={Link}
                    to={`/user/organization/accounts/byType/${accountType.key}`}
                    key={`/user/organization/accounts/byType/${accountType.key}`}
                    selected={pathname.startsWith(
                      `/user/organization/accounts/byType/${accountType.key}`,
                    )}
                    sx={{ flex: 0 }}
                    onClick={() => setAdministrationOpen(false)}
                  >
                    <ListItemText
                      primary={pluralize.plural(accountType.name)}
                      sx={{ ml: 6 }}
                      disableTypography
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        )}

        <ListItem sx={{ flex: 1 }}></ListItem>
        {administrationOpen && canAdministrate && (
          <>
            {settingLinks
              .filter((sub) => sub.visible)
              .map((sub) => (
                <NavLink
                  dataCy={sub.name + "-btn"}
                  {...sub}
                  key={sub.link}
                  sx={{ flex: 0 }}
                />
              ))}
            {canLeave && (
              <NavLink
                title="Create Account Type"
                icon={<PeopleOutline />}
                onClick={() => setCreateAccountTypeDialogOpen(true)}
                sx={{ flex: 0 }}
              ></NavLink>
            )}
          </>
        )}
        {membershipOpen && !canAdministrate && (
          <>
            {MEMBERSHIP_LINKS.filter((sub) => sub.visible).map((sub) => (
              <NavLink
                dataCy={sub.name + "-btn"}
                {...sub}
                key={sub.link}
                sx={{ flex: 0 }}
              />
            ))}
            {canLeave && (
              <NavLink
                title="Leave Organization"
                icon={<Logout />}
                onClick={() => setLeaveOrganizationDialogOpen(true)}
                sx={{ flex: 0 }}
              ></NavLink>
            )}
          </>
        )}
        {canAdministrate && (
          <NavLink
            title="Administration"
            icon={<Settings />}
            dataCy={"administration-menu-btn"}
            onClick={() => setAdministrationOpen((open) => !open)}
            sx={{ flex: 0 }}
          >
            {administrationOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </NavLink>
        )}

        {!canAdministrate && (
          <NavLink
            title="Membership"
            icon={<Person />}
            dataCy={"membership-menu-btn"}
            onClick={() => setMembershipOpen((open) => !open)}
            sx={{ flex: 0 }}
          >
            {membershipOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </NavLink>
        )}
      </List>

      {organization && (
        <CreateAccountTypeDialog
          open={createAccountTypeDialogOpen}
          organizationId={organization.id}
          onClose={() => setCreateAccountTypeDialogOpen(false)}
          onSave={(accountType) => {
            const newAccountTypes = accountTypes?.slice();
            newAccountTypes?.push(accountType);
            setAccountTypes(newAccountTypes);

            navigate(`/user/organization/accounts/byType/${accountType.key}`);
          }}
        ></CreateAccountTypeDialog>
      )}
      <LeaveOrganizationDialog
        title={"Leave Organization"}
        open={leaveOrganizationDialogOpen}
        onClose={() => setLeaveOrganizationDialogOpen(false)}
        onSave={() => {
          setLeaveOrganizationDialogOpen(false);
          leaveOrganizationHandle();
        }}
      ></LeaveOrganizationDialog>
    </Box>
  );
};
export default OrganizationDrawerMenu;
