import WebSocketMessage, {
  WebSocketMessageType,
  WebSocketNotificationData,
} from "../../model/WebSocketMessage";
import eventEmitter from "../event";
import { showNotification } from "../notification";
import { createWebSocketMessage, WebSocketGlobalState } from "./websocket";

/**
 * Creates a notification interaction object to send back to the server
 * Gets the active websocket connection and sends the interaction message
 */
export const createNotificationInteraction = (
  interaction: string,
  id: string,
) => {
  const message = createWebSocketMessage(
    WebSocketMessageType.PUSH_NOTIFICATION_INTERACTION,
    {
      interaction,
      notification_id: id,
    },
  );

  const activeWebSocket = WebSocketGlobalState.getWebSocket();
  if (activeWebSocket) {
    activeWebSocket.send(JSON.stringify(message));
  } else {
    console.error("No active websocket connection");
  }
};

/**
 * Handles a PUSH_NOTIFICATION event from the server
 * Creates a Notification using an implementation from Mozilla docs
 * https://developer.mozilla.org/en-US/docs/Web/API/Notification
 */
export const handleNotification = async (message: WebSocketMessage) => {
  // in the message, expect the properties:
  // - title: string
  // - body: string
  // - icon: string

  // try to convert into WebSocketNotificationData
  try {
    const notificationData = message.data as WebSocketNotificationData;
    // pass in data, as well as the interaction functions
    eventEmitter.emit("websocket:notification", notificationData);
    if (notificationData.type == "task") {
      eventEmitter.emit("websocket:taskNotification", notificationData);
    } else if (notificationData.type == "invitation") {
      eventEmitter.emit("websocket:invitationNotification", notificationData);
    } else {
      // show notification natively
      // default notification shows with Runway logo as icon
      // no click actions are needed
      showNotification(
        notificationData.title,
        notificationData.body,
        notificationData.id,
        notificationData.icon,
        () => {
          window.focus();
        },
        () => {},
        () => {},
      );
    }
  } catch (e) {
    console.error(e);
  }
};
