import GoogleIcon from "@mui/icons-material/Google";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IdentityProvider,
  IdentityProviderFlow,
  authenticateUserWithAuthCodeGrant,
  federatedAuthroizationEndpoint,
} from "../../../lib/auth";

export function GoogleAuthRedirect() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const authCodeGrant = searchParams.get("code");
    const stateValue: { authValue?: string; origin?: string } =
      searchParams.get("state")
        ? JSON.parse(atob(searchParams.get("state")!))
        : {};

    const redirectUrl = stateValue.origin
      ? new URL(stateValue.origin)
      : undefined;

    // Redirect to another origin if necessary
    if (redirectUrl && redirectUrl.origin != window.location.origin) {
      window.location.href = `${redirectUrl.origin}${window.location.pathname}${window.location.search}`;
    }

    // If user is redirected from /oath2/authorize endpoint, complete login
    if (authCodeGrant && stateValue.authValue) {
      searchParams.delete("code");
      searchParams.delete("state");
      setSearchParams(searchParams);
      authenticateUserWithAuthCodeGrant(
        authCodeGrant,
        stateValue.authValue,
        IdentityProviderFlow.SignIn,
      ).then(() => {
        if (redirectUrl) {
          window.location.href = `${window.location.origin}${redirectUrl.pathname}`;
        }
      });
    }
  }, [navigate, searchParams, setSearchParams]);

  return <></>;
}

export function GoogleAuthButton({
  setAuthenticating,
  origin,
  label,
}: {
  setAuthenticating: (state: boolean) => void;
  origin: string;
  label: string;
}) {
  return (
    <Button
      onClick={() => {
        setAuthenticating(true);
        // routes the user to the external providers login UI
        // in our case the user is routed to Google's sign-in page
        window.location.href = federatedAuthroizationEndpoint(
          IdentityProvider.Google,
          origin,
        );
      }}
      fullWidth
      variant={"outlined"}
      sx={{
        mt: 3,
        py: 1.5,
        color: "text.secondary",
        borderColor: "text.secondary",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
        },
      }}
      startIcon={<GoogleIcon />}
    >
      {label} with Google
    </Button>
  );
}
