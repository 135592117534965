import Notification, { NotificationState } from "../model/Notification";
import API from "./base";

const getNotification = async (notificationId: string) => {
  const result = await API.get(`/notifications/${notificationId}`, {
    public: true,
  });

  return result.data.payload as Notification;
};

const getNotificationsByUser = async (userId: string, limit: number = 20) => {
  const result = await API.get(`/notifications/byUser/${userId}`, {
    public: true,
    params: { limit },
  });

  return result.data.payload as Notification[];
};

const getNotificationsByUserAndOrganization = async (
  userId: string,
  organizationId: string,
  limit: number = 20,
) => {
  const result = await API.get(
    `/notifications/byUser/${userId}/byOrganization/${organizationId}`,
    {
      public: true,
      params: { limit },
    },
  );

  return result.data.payload as NotificationState;
};

const markAllNotificationsStatus = async (
  userId: string,
  status: string,
  organizationId: string,
) => {
  await API.post(`/notifications/markStatusAll/${userId}/${organizationId}`, {
    status,
  });

  return true;
};

const markNotificationStatus = async (
  notificationId: string,
  status: string,
) => {
  await API.post(`/notifications/${notificationId}/markStatus`, {
    status,
  });

  return true;
};

export default {
  getNotification,
  getNotificationsByUser,
  getNotificationsByUserAndOrganization,
  markAllNotificationsStatus,
  markNotificationStatus,
};
