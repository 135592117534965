import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Dashboard } from "../components/layout";
import NavigateHome from "../components/navigation/NavigateHome";
import RequireAuth from "../components/require-auth";
import {
  AccountListPage,
  AccountsIsMemberOfListPage,
  AccountTypeAccountsListPage,
  AccountTypeProcessListPage,
} from "../features/accounts";
import AccountTypeAffinityPage from "../features/accounts/AccountTypeAffinityPage";
import AccountTypePage from "../features/accounts/AccountTypePage";
import { AccountProfileViewer } from "../features/accounts/types";
import { App } from "../features/app";
import NotFound from "../features/app/NotFound";
import SharedPage from "../features/app/SharedPage";
import ManageEmailFiltersPage from "../features/email-filters/ManageEmailFiltersPage";
import { IntegrationsPage } from "../features/integrations";
import ManageInvitationPage from "../features/invitations/ManageInvitationPage";
import {
  GlobalOrganizationProvider,
  OrganizationAccountTypePublicPage,
  OrganizationCategoryPublicProcessPage,
  OrganizationPage,
  PublicOrganizationLayout,
} from "../features/organization";
import { PublicOrganizationPage } from "../features/organization/";
import ConfirmOrganizationEmailPage from "../features/organization/ConfirmOrganizationEmailPage";
import CreateOrganizationPage from "../features/organization/CreateOrganizationPage";
import InternalLayout from "../features/organization/InternalLayout";
import InternalOrgLayout from "../features/organization/InternalOrgLayout";
import ProfileDrawerMenu from "../features/organization/ProfileDrawerMenu";
import UpdateOrganizationPage from "../features/organization/UpdateOrganizationPage";
import { OrganizationAddressSettings } from "../features/organization/management/OrganizationAddressSettings";
import { OrganizationGeneralSettings } from "../features/organization/management/OrganizationGeneralSettings";
import { OrganizationSubscriptionSettings } from "../features/organization/management/OrganizationSubscriptionSettings";
import { ProcessPage, ProcessViewPage } from "../features/process";
import AccountTaskExecutionPage from "../features/process/AccountTaskExecutionPage";
import OrganizationProcessExecutionsPage from "../features/process/components/OrganizationProcessExecutionsPage";
import ProcessExecutionLongTaskFormPage from "../features/process/components/ProcessExecutionLongTaskFormPage";
import ProcessExecutionLongTaskFormSharePage from "../features/process/components/ProcessExecutionLongTaskFormSharePage";
import ProcessExecutionViewPage from "../features/process/components/ProcessExecutionViewPage";
import SingleProcessWrapper, {
  SingleProcessViewType,
} from "../features/process/components/SingleProcessPage/SingleProcessWrapper";
import OnboardingExecutorPage from "../features/public-executor/OnboardingExecutionPage";
import { PublicProcessExecutionPage } from "../features/public-executor/PublicProcessExecutionPage";
import RoleListPage from "../features/role/RoleListPage";
import TaskPage from "../features/task/components/TaskPage";
import {
  ForgotPasswordEmailSentPage,
  ForgotPasswordPage,
  ForgotPasswordResetPage,
  RegisterPage,
  SignInPage,
  UserChangePasswordPage,
  VerificationPage,
} from "../features/user";
import ApiKeysPage from "../features/user/ApiKeysPage";
import UserDashboardPage from "../features/user/UserDashboardPage";
import UserPreferencePage from "../features/user/UserPreferencePage";
import UserProfilePage from "../features/user/UserProfilePage";
import { GoogleAuthRedirect } from "../features/user/components/GoogleAuth";
import useFeature from "../hooks/useFeature";
import { DefaultAlertSnackbar } from "../lib/alert";
import { environment } from "../util";
import { AccountPageRoutes } from "./AccountPageRoutes";

const DashboardRoute = lazy(
  () => import("../features/dashboard/DashboardRoute"),
);

const showCreateAccount = true;
/*  !!localStorage.getItem("runway.register") ||
  process.env.REACT_APP_ENVIRONMENT == "local";*/

const OrganizationRoutes = () => {
  const canViewDashboard = useFeature("dashboard");
  const canViewEmailFilterManagement = useFeature("emailFilters");
  return (
    <Routes>
      <Route
        path=":organizationId/confirm"
        element={<ConfirmOrganizationEmailPage />}
      />
      {/* 2. Hide organization management pages when enterprise account exists */}

      <Route path="manage" element={<UpdateOrganizationPage />}>
        <Route path="general" element={<OrganizationGeneralSettings />} />
        <Route
          path="subscription"
          element={<OrganizationSubscriptionSettings />}
        />
        <Route path="addresses" element={<OrganizationAddressSettings />} />
      </Route>
      {/* End #2 */}

      <Route
        path="executions"
        element={<OrganizationProcessExecutionsPage />}
      />
      {canViewDashboard && (
        <Route
          path="dashboards/:dashboardKey"
          element={
            <Suspense>
              <DashboardRoute />
            </Suspense>
          }
        />
      )}
      <Route path="roles" element={<RoleListPage />} />
      <Route path="members" element={<OrganizationPage />} />
      <Route path="accounts">
        <Route path="" element={<AccountListPage />} />
        <Route
          path="byType/:accountTypeKey"
          element={<AccountTypeAccountsListPage />}
        />
        <Route
          path="byType/:accountTypeKey/processes"
          element={<AccountTypeProcessListPage />}
        />
        <Route
          path="*"
          element={<AccountPageRoutes viewer={AccountProfileViewer.Admin} />}
        />
      </Route>
      <Route path="processes/:processId" element={<ProcessViewPage />} />
      <Route path="processes" element={<ProcessPage />} index />
      <Route path="processes/:processId/tasks/:taskId" element={<TaskPage />} />
      <Route
        path="processes/:processId/tasks/:taskId/elements/:elementId"
        element={<TaskPage />}
      />
      <Route
        path="processes/:processId/tasks/:taskId/elements/:elementId/keys/:key"
        element={<TaskPage />}
      />
      <Route path="executions/:processId">
        {localStorage.getItem("runway.singleProcess") == "true" && (
          <>
            <Route path="" element={<Navigate to="table" />} />
            <Route
              path="table"
              element={
                <SingleProcessWrapper view={SingleProcessViewType.TABLE} />
              }
            />
            <Route
              path="board"
              element={
                <SingleProcessWrapper view={SingleProcessViewType.BOARD} />
              }
            />
            <Route path="*" element={<NotFound />} />
          </>
        )}
        <Route
          path=":processExecutionId"
          element={<ProcessExecutionViewPage />}
        />
        <Route
          path=":processExecutionId/tasks/:taskId/form"
          element={<ProcessExecutionLongTaskFormPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      {canViewEmailFilterManagement && (
        <Route path="email-filters" element={<ManageEmailFiltersPage />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          path="user"
          element={
            <RequireAuth>
              <DefaultAlertSnackbar>
                <GlobalOrganizationProvider
                  orgKey={environment.organization?.key}
                >
                  <Dashboard>
                    <Outlet />
                  </Dashboard>
                </GlobalOrganizationProvider>
              </DefaultAlertSnackbar>
            </RequireAuth>
          }
        >
          <Route
            path="profile"
            element={
              <InternalLayout menu={ProfileDrawerMenu}>
                <UserProfilePage />
              </InternalLayout>
            }
          >
            <Route path="preferences" element={<UserPreferencePage />} />

            <Route path="organizations" element={<UserDashboardPage />} />
          </Route>
          {/* 1. Replace ProcessExecutionListPage in ECP */}
          <Route path="dashboard" element={<UserDashboardPage />} />
          <Route path="organizations">
            <Route path="accounts">
              <Route
                path="member/:userId"
                element={<AccountsIsMemberOfListPage />}
              />
              <Route
                path="*"
                element={
                  <AccountPageRoutes viewer={AccountProfileViewer.User} />
                }
              />
            </Route>
            <Route path="create" element={<CreateOrganizationPage />} />
          </Route>
          <Route
            path="executions/:processExecutionId"
            element={<ProcessExecutionViewPage />}
          />
          <Route
            path="organization"
            element={
              <InternalOrgLayout>
                <Outlet />
              </InternalOrgLayout>
            }
          >
            <Route path="*" element={<OrganizationRoutes />} />
          </Route>
          <Route path="integrations" element={<IntegrationsPage />} />
          <Route path="apikeys" element={<ApiKeysPage />} />
          <Route path="account-types" element={<AccountTypePage />} />
          <Route
            path="account-type/:accountTypeId/affinity"
            element={<AccountTypeAffinityPage />}
          />
          <Route
            path="share/:taskExecutionId/form"
            element={<ProcessExecutionLongTaskFormSharePage />}
          />
          <Route index element={<NavigateHome />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="verify" element={<VerificationPage />} />
        {showCreateAccount && (
          <Route path="register" element={<RegisterPage />} />
        )}
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="google-auth-redirect" element={<GoogleAuthRedirect />} />
        <Route
          path="forgot-password"
          element={
            <ForgotPasswordPage
              clientMetadata={{
                whitelabelUrl: window.location.origin,
                sesConfigId: environment.app.sesConfigId ?? "",
                logoUrl: environment.app.logo
                  ? window.location.origin + environment.app.logo
                  : "",
              }}
            />
          }
        />
        <Route
          path="forgot-password-email-sent"
          element={<ForgotPasswordEmailSentPage />}
        />
        <Route path="reset-password" element={<ForgotPasswordResetPage />} />
        <Route
          path="new-password-challenge"
          element={<UserChangePasswordPage />}
        />
        <Route
          path="manage-invitation"
          element={
            <GlobalOrganizationProvider>
              <ManageInvitationPage />
            </GlobalOrganizationProvider>
          }
        />
        <Route path="*" element={<NotFound />} />
        {/* 3. Move submit an idea pages into common public executor template */}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="share" element={<SharedPage />}>
        <Route
          path=":taskExecutionId/form"
          element={<ProcessExecutionLongTaskFormSharePage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path="/:orgKey/onboarding/:publicExecutorId"
        element={<OnboardingExecutorPage />}
      />
      <Route path="/:orgKey/" element={<PublicOrganizationLayout />}>
        <Route
          path="dashboards/:dashboardKey"
          element={
            <Suspense>
              <DashboardRoute />
            </Suspense>
          }
        />
        <Route
          path="works/:publicExecutorId"
          element={<PublicProcessExecutionPage />}
        >
          <Route
            path="executions/:processExecutionId"
            element={<PublicProcessExecutionPage />}
          />
        </Route>
        <Route
          path="for/:accountTypeKey/"
          element={<OrganizationAccountTypePublicPage />}
        />
        <Route
          path="category/:categoryId"
          element={<OrganizationCategoryPublicProcessPage />}
        />
        <Route path="share" element={<SharedPage />}>
          <Route
            path=":processExecutionId/task/:taskId"
            element={<AccountTaskExecutionPage showAttachments={false} />}
          />
          <Route
            path=":taskExecutionId/form"
            element={<ProcessExecutionLongTaskFormSharePage />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route index element={<PublicOrganizationPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
