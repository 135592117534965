export enum OrganizationAddressLabels {
  PRIMARY = 0,
  SECONDARY,
  BILLING,
}

export class Address {
  id = "";

  first_line = "";

  second_line = "";

  city = "";

  state = "";

  zip_code: number = 10000;
}

export class AddressEntry {
  organization_id = "";

  user_id: string | null = "";

  address_id = "";

  label: OrganizationAddressLabels = OrganizationAddressLabels.PRIMARY;

  id = "";
}
