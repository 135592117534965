import { createContext, useContext } from "react";
import { Organization } from "../../../model";

export interface OrganizationContextProps {
  organization: Organization;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (err: any) => void;
  onSubmit: (organization: Organization) => Promise<void>;
  disabled: boolean | undefined;
  buttonAlignment: "left" | "right" | undefined;
  buttonText?: string;
  emailDisabled?: boolean;
  onOrganizationDeleted?: (organization: Organization) => void;
  isCreatingOrganization?: boolean;
  viewSubscriptions?: boolean;

  setOrganization: (organization: Organization) => void;
  refreshOrganizations: () => void;
}

export const UpdateOrganizationContext = createContext<
  OrganizationContextProps | undefined
>(undefined);

export const useUpdateOrganizationProps = (): OrganizationContextProps => {
  const context = useContext(UpdateOrganizationContext);
  if (!context) {
    throw new Error(
      "useOrganization must be used within an OrganizationProvider",
    );
  }
  return context;
};
