import { Address } from "../../model/Address";

const getPlaceByString = async (input: string): Promise<Array<Address>> => {
  const { Place } = (await google.maps.importLibrary(
    "places",
  )) as google.maps.PlacesLibrary;

  const request = {
    textQuery: input,
    fields: ["addressComponents"],
    language: "en-US",
  };

  const addrArray: Array<Address> = [];
  const { places } = await Place.searchByText(request);

  places.forEach((place) => {
    if (place.addressComponents) {
      const components = place.addressComponents;

      const getComponent = (type: string) =>
        components.find((component) => component.types.includes(type))
          ?.longText || "";

      const getComponentShort = (type: string) =>
        components.find((component) => component.types.includes(type))
          ?.shortText || "";

      const addressObj: Address = {
        id: "",
        first_line: `${getComponent("street_number")} ${getComponent("route")}`,
        second_line: "",
        city: getComponent("locality"),
        state: getComponentShort("administrative_area_level_1"),
        zip_code: +getComponent("postal_code"),
      };

      addrArray.push(addressObj);
    }
  });

  return addrArray;
};

const getSuggestionAsAddress = async (
  suggestion: google.maps.places.AutocompleteSuggestion,
): Promise<Address | undefined> => {
  const placeId = suggestion.placePrediction?.placeId;
  if (!placeId) return undefined;

  const placeService = new google.maps.places.Place({ id: placeId });
  await placeService.fetchFields({
    fields: ["addressComponents", "displayName"],
  });

  const components = placeService.addressComponents;
  if (!components) return undefined;

  const getComponent = (type: string) =>
    components.find((component) => component.types.includes(type))?.longText ||
    "";

  const getComponentShort = (type: string) =>
    components.find((component) => component.types.includes(type))?.shortText ||
    "";

  const streetNum = getComponent("street_number");
  const route = getComponent("route");

  if (!route || !streetNum) return undefined;

  const final: Address = {
    id: "",
    first_line: `${streetNum} ${route}`.trim(),
    second_line: "",
    city: getComponent("locality"),
    state: getComponentShort("administrative_area_level_1"),
    zip_code: +getComponent("postal_code"),
  } as Address;

  return final;
};

const getAutocompleteSuggetsions = async (
  input: string,
): Promise<Array<Address>> => {
  if (!input) return [];
  const { AutocompleteSessionToken, AutocompleteSuggestion } =
    (await google.maps.importLibrary("places")) as google.maps.PlacesLibrary;
  const token = new AutocompleteSessionToken();
  const request: google.maps.places.AutocompleteRequest = {
    input: input,
    includedPrimaryTypes: ["geocode"],
    region: "us",
    language: "en-US",
    sessionToken: token,
  };

  const result = (
    await AutocompleteSuggestion.fetchAutocompleteSuggestions(request)
  ).suggestions;

  const addresses = await Promise.all(
    result.map(async (suggestion) => {
      const addy = await getSuggestionAsAddress(suggestion);
      return addy;
    }),
  );

  const filteredAddresses = addresses.filter(
    (addy) => addy !== undefined,
  ) as Address[];

  return filteredAddresses;
};

export default {
  getPlaceByString,
  getAutocompleteSuggetsions,
  getSuggestionAsAddress,
};
