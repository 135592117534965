import {
  ArrowRightAlt,
  Close,
  Payments,
  Sell,
  Speed,
  Warning,
} from "@mui/icons-material";
import {
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { OrganizationAPI } from "../../../api";
import Organization from "../../../model/Organization";
import messages from "../../process/messages";

const RequestTrackerReport = ({
  organization,
  billingStartDate,
  billingEndDate,
}: {
  organization: Organization;
  billingStartDate?: Date;
  billingEndDate?: Date;
}) => {
  const intl = useIntl();
  const executionMsg = intl.formatMessage(messages.execution.props);
  const executionsMsg = intl.formatMessage(messages.executions.props);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const showBillingRange = billingStartDate && billingEndDate;

  useEffect(() => {
    setLoading(true);
    if (!startDate || !endDate) return;

    if (startDate > endDate) {
      setError("Start date cannot be after end date");
      return;
    }

    OrganizationAPI.getMeteredSubscriptionInfo(
      organization.id,
      startDate,
      endDate,
    )
      .then((data) => {
        setPricePerProcess(data.price ? data.price / 100 : undefined);
        setProcessesCompleted(data.amount);
        if (data.price) {
          setTotalPrice((data.price * data.amount) / 100);
        } else {
          setTotalPrice(undefined);
        }

        setIsReportWarning(data.show_summary_warning);

        setError(undefined);
        setLoading(false);
      })
      .catch(() => {
        setError("An unknown error occurred");
      });
  }, [organization.id, startDate, endDate]);

  const [pricePerProcess, setPricePerProcess] = useState<number | undefined>(
    undefined,
  );
  const [processesCompleted, setProcessesCompleted] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number | undefined>(0);
  const [isReportWarning, setIsReportWarning] = useState<boolean>(false);

  const [filterOption, setFilterOption] = useState<
    "30d" | "90d" | "billing" | "custom" | undefined
  >("30d");

  useEffect(() => {
    const value = filterOption;
    if (value == undefined) {
      setStartDate(undefined);
      setEndDate(undefined);
    } else if (value == "30d") {
      const date = new Date();
      date.setDate(date.getDate() - 30);
      setStartDate(date);
      setEndDate(new Date());
    } else if (value == "90d") {
      const date = new Date();
      date.setDate(date.getDate() - 90);
      setStartDate(date);
      setEndDate(new Date());
    }
  }, [filterOption]);

  return (
    <>
      <Box display={"flex"} my={2}>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={filterOption}
          exclusive
          onChange={(evt, value) => {
            setFilterOption(value);
          }}
          sx={{
            marginRight: 1,
          }}
          aria-label="Filter"
        >
          {showBillingRange ? (
            <ToggleButton size="small" value="billing">
              Billing Range
            </ToggleButton>
          ) : (
            <ToggleButton size="small" value="30d">
              Last 30 Days
            </ToggleButton>
          )}

          <ToggleButton size="small" value="90d">
            Last 90 Days
          </ToggleButton>
          <ToggleButton size="small" value="custom">
            Custom
          </ToggleButton>
          {/* <ToggleButton
            size="small"
            value="search"
            sx={{ mr: 1 }}
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <GridSearchIcon />
          </ToggleButton> */}
        </ToggleButtonGroup>
        {filterOption === "custom" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              onChange={(value) => {
                const date = value?.toDate();
                if (!date) setStartDate(undefined);
                setStartDate(date);
              }}
              slotProps={{ textField: { size: "small" } }}
              sx={{ marginRight: 1 }}
              value={startDate ? dayjs(startDate) : undefined}
              disableFuture={true}
            />
            <DatePicker
              label="End Date"
              onChange={(value) => {
                const date = value?.toDate();
                if (!date) setEndDate(undefined);
                setEndDate(date);
              }}
              value={endDate ? dayjs(endDate) : undefined}
              slotProps={{ textField: { size: "small" } }}
              disableFuture={true}
            />
            {/* <Button
            onClick={() => {
              setStartDate(undefined);
              setEndDate(undefined);
            }}
            sx={{ marginLeft: 1 }}
          >
            Clear
          </Button> */}
            {/* <Button
            onClick={() => {
              // Set the start date to 30 days ago
              const date = new Date();
              date.setDate(date.getDate() - 30);
              setStartDate(date);
              setEndDate(new Date());
            }}
            sx={{ marginLeft: 1 }}
            variant="contained"
          >
            Last 30 Days
          </Button>
          <Button
            onClick={() => {
              // Set the start date to 90 days ago
              const date = new Date();
              date.setDate(date.getDate() - 90);
              setStartDate(date);
              setEndDate(new Date());
            }}
            sx={{ marginLeft: 1 }}
            variant="contained"
          >
            Last 90 Days
          </Button> */}
          </LocalizationProvider>
        )}
      </Box>
      {error && <Typography color={"error"}>{error}</Typography>}
      {!error && startDate && endDate && (
        <>
          {loading && (
            <>
              <CircularProgress
                data-cy={"circular-progress"}
                size={24}
                sx={{
                  my: 2,
                }}
              />
            </>
          )}
          {!loading && (
            <>
              <Box display={"flex"} alignItems={"center"} marginTop={2}>
                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                    borderRadius: "10px",
                    py: 1,
                    px: 2,
                    mr: 2,
                  }}
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Speed fontSize="small" />
                    <Typography variant="h3" marginLeft={1} fontWeight={"bold"}>
                      {processesCompleted}
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    {executionsMsg} Complete
                  </Typography>
                </Box>

                <Close
                  fontSize="small"
                  sx={{
                    mr: 2,
                  }}
                />

                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                    borderRadius: "10px",
                    py: 1,
                    px: 2,
                    mr: 2,
                  }}
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Sell fontSize="small" />
                    <Typography variant="h3" marginLeft={1} fontWeight={"bold"}>
                      {pricePerProcess != undefined
                        ? "$" + pricePerProcess.toFixed(2)
                        : "???"}
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    Price Per {executionMsg}
                  </Typography>
                </Box>

                <ArrowRightAlt
                  fontSize="small"
                  sx={{
                    mr: 2,
                  }}
                />

                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.grey[200],
                    borderRadius: "10px",
                    py: 1,
                    px: 2,
                    mr: 2,
                  }}
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Payments fontSize="small" />
                    <Typography variant="h3" marginLeft={1} fontWeight={"bold"}>
                      {totalPrice != undefined
                        ? "$" + totalPrice.toFixed(2)
                        : "???"}
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    Estimated Total Price
                  </Typography>
                </Box>
              </Box>
              {isReportWarning ? (
                <Tooltip
                  placement="bottom-start"
                  title={`A ${executionMsg} was completed in the last 5 minutes. It might not be included in this report.`}
                >
                  <Box display={"flex"} my={2}>
                    <Warning fontSize="small" color="warning" />
                    <Typography
                      variant="caption"
                      sx={{
                        color: (theme) => theme.palette.warning.main,
                      }}
                      marginLeft={1}
                    >
                      This report may be outdated and may not reflect the most
                      recent {executionsMsg}
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Box my={2}>
                  <Typography variant="caption" fontStyle={"italic"}>
                    This report does not reflect any credits or discounts
                    applied.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default RequestTrackerReport;
