import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Address } from "../../model/Address";
import { AddressAutocomplete } from "./AddressAutocomplete";
interface AddressFormProps {
  current?: Address;
  noValidate?: boolean; // If the form should require a valid address first or not
  condensed?: boolean; // Hide manual form
  manual?: boolean; // Hide autocomplete form
  noControls?: boolean; // Hide submit/cancel buttons
  autoSubmit?: boolean;
  onSubmit?: (address: Address) => void;
  onChange?: (address: Address) => void;
  onCancel?: () => void;
}

interface State {
  fullName: string;
  abbreviation: string;
}
const states: Array<State> = [
  { fullName: "Alabama", abbreviation: "AL" },
  { fullName: "Alaska", abbreviation: "AK" },
  { fullName: "Arizona", abbreviation: "AZ" },
  { fullName: "Arkansas", abbreviation: "AR" },
  { fullName: "California", abbreviation: "CA" },
  { fullName: "Colorado", abbreviation: "CO" },
  { fullName: "Connecticut", abbreviation: "CT" },
  { fullName: "Delaware", abbreviation: "DE" },
  { fullName: "Florida", abbreviation: "FL" },
  { fullName: "Georgia", abbreviation: "GA" },
  { fullName: "Hawaii", abbreviation: "HI" },
  { fullName: "Idaho", abbreviation: "ID" },
  { fullName: "Illinois", abbreviation: "IL" },
  { fullName: "Indiana", abbreviation: "IN" },
  { fullName: "Iowa", abbreviation: "IA" },
  { fullName: "Kansas", abbreviation: "KS" },
  { fullName: "Kentucky", abbreviation: "KY" },
  { fullName: "Louisiana", abbreviation: "LA" },
  { fullName: "Maine", abbreviation: "ME" },
  { fullName: "Maryland", abbreviation: "MD" },
  { fullName: "Massachusetts", abbreviation: "MA" },
  { fullName: "Michigan", abbreviation: "MI" },
  { fullName: "Minnesota", abbreviation: "MN" },
  { fullName: "Mississippi", abbreviation: "MS" },
  { fullName: "Missouri", abbreviation: "MO" },
  { fullName: "Montana", abbreviation: "MT" },
  { fullName: "Nebraska", abbreviation: "NE" },
  { fullName: "Nevada", abbreviation: "NV" },
  { fullName: "New Hampshire", abbreviation: "NH" },
  { fullName: "New Jersey", abbreviation: "NJ" },
  { fullName: "New Mexico", abbreviation: "NM" },
  { fullName: "New York", abbreviation: "NY" },
  { fullName: "North Carolina", abbreviation: "NC" },
  { fullName: "North Dakota", abbreviation: "ND" },
  { fullName: "Ohio", abbreviation: "OH" },
  { fullName: "Oklahoma", abbreviation: "OK" },
  { fullName: "Oregon", abbreviation: "OR" },
  { fullName: "Pennsylvania", abbreviation: "PA" },
  { fullName: "Rhode Island", abbreviation: "RI" },
  { fullName: "South Carolina", abbreviation: "SC" },
  { fullName: "South Dakota", abbreviation: "SD" },
  { fullName: "Tennessee", abbreviation: "TN" },
  { fullName: "Texas", abbreviation: "TX" },
  { fullName: "Utah", abbreviation: "UT" },
  { fullName: "Vermont", abbreviation: "VT" },
  { fullName: "Virginia", abbreviation: "VA" },
  { fullName: "Washington", abbreviation: "WA" },
  { fullName: "West Virginia", abbreviation: "WV" },
  { fullName: "Wisconsin", abbreviation: "WI" },
  { fullName: "Wyoming", abbreviation: "WY" },
];

/**
 * A reusable form used to retrieve an address from the user.
 *
 * Methods to retrieve address:
 * @param onSubmit Returns the new address from the user when they press submit.
 * @param onChange Returns the new address from the user every time they update.
 * @param onCancel Enables the cancel button and assigns functionality.
 *
 * Properties:
 * @param current Autofills the form with the set address.
 * @param noValidate Disables validation preventing sumission of the form.
 * @param condensed Disables the manual form.
 * @param manual Disables the autocomplete form.
 * @param noControls Hides the control buttons.
 * @param autoSubmit Setting to true sets form to submit as soon as autocomplete sends a value
 */
const AddressForm: FC<AddressFormProps> = ({ current, ...props }) => {
  const [address, setAddress] = useState<Address | undefined>(current);
  const [errorText, setErrorText] = useState<string>("");
  const [stateValue, setStateValue] = useState<State | null>(
    states.find((st) => st.abbreviation === address?.state) || null,
  );
  const [stateText, setStateText] = useState<string>(
    states.find((st) => st.abbreviation === address?.state)?.fullName || "",
  );

  const onSubmit = () => {
    setErrorText("");
    let canSubmit = true;

    const firstLineRegex = /^[A-Za-z0-9][A-Za-z0-9 ]*$/;
    const cityStateRegex = /^[A-Za-z0-9 ]+$/;
    const zipCodeRegex = /^\d+$/;

    if (!address) canSubmit = false;
    if (!props.noValidate && canSubmit && address) {
      if (
        !firstLineRegex.test(address.first_line) ||
        address.first_line == ""
      ) {
        canSubmit = false;
        setErrorText("Cannot submit address: First line is invalid.");
      }
      if (!cityStateRegex.test(address.state) || address.state == "") {
        canSubmit = false;
        setErrorText("Cannot submit address: State is invalid.");
      }
      if (!cityStateRegex.test(address.city) || address.city == "") {
        canSubmit = false;
        setErrorText("Cannot submit address: City is invalid.");
      }
      if (
        !zipCodeRegex.test(address.zip_code.toString()) ||
        address.zip_code == 0
      ) {
        canSubmit = false;
        setErrorText("Cannot submit address: Zip code is invalid.");
      }
    }
    if (props.onSubmit && address && canSubmit) props.onSubmit(address);
  };

  const onCancel = () => {
    if (props.onCancel) props.onCancel();
  };

  // if address is undefined, set to blank
  useEffect(() => {
    if (!address) {
      setAddress({
        id: "",
        first_line: "",
        second_line: "",
        city: "",
        state: "",
        zip_code: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={1} direction={"row"}>
        <Grid item xs={12}>
          {!props.manual && (
            <AddressAutocomplete
              onSubmit={(address) => {
                setAddress(address);
                if (props.onSubmit && props.autoSubmit) props.onSubmit(address);

                const state: State | undefined = states.find((state) =>
                  state.abbreviation
                    .toLowerCase()
                    .match(address.state.toLowerCase()),
                );
                setStateValue(state ? state : null);
                setStateText(state?.fullName || "");
              }}
            />
          )}
        </Grid>
        {!props.condensed && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                value={address?.first_line || ""}
                size="small"
                id="line-one"
                label="Address Line One"
                variant="filled"
                onChange={(event) => {
                  if (address) {
                    setAddress({ ...address, first_line: event.target.value });
                    if (props.onChange) props.onChange(address);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={address?.second_line || ""}
                size="small"
                id="line-two"
                label="Address Line Two"
                variant="filled"
                onChange={(event) => {
                  if (address) {
                    setAddress({ ...address, second_line: event.target.value });
                    if (props.onChange) props.onChange(address);
                  }
                }}
              />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required
                  value={address?.city || ""}
                  size="small"
                  id="city"
                  label="City"
                  variant="filled"
                  onChange={(event) => {
                    if (address) {
                      setAddress({ ...address, city: event.target.value });
                      if (props.onChange) props.onChange(address);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={stateValue}
                  inputValue={stateText}
                  renderInput={(params) => (
                    <TextField
                      variant="filled"
                      label="State"
                      {...params}
                      size="small"
                    />
                  )}
                  options={states}
                  renderOption={(props, option) => {
                    return (
                      <Box {...props} key={option.abbreviation} component="li">
                        {option.fullName}
                      </Box>
                    );
                  }}
                  getOptionLabel={(option) => option.fullName}
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        option.fullName
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                        option.abbreviation
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()),
                    )
                  }
                  onInputChange={(_, newValue, reason) => {
                    if (reason == "clear") setStateText("");
                    else if (reason == "input") setStateText(newValue);
                    else if (reason == "reset") setStateText("");
                  }}
                  onChange={(_, newValue) => {
                    if (address && newValue) {
                      setAddress({ ...address, state: newValue.abbreviation });
                      setStateText(newValue.fullName);
                      if (props.onChange) props.onChange(address);
                    }
                  }}
                  onBlur={() => {
                    if (address && stateText != "") {
                      const inputValue = stateText.toLowerCase();
                      const closestMatch = states.find(
                        (state) =>
                          state.fullName.toLowerCase().includes(inputValue) ||
                          state.abbreviation.toLowerCase().includes(inputValue),
                      );
                      if (closestMatch) {
                        setAddress({
                          ...address,
                          state: closestMatch.abbreviation,
                        });
                        setStateText(closestMatch.fullName);
                        setStateValue(closestMatch);
                      }
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.abbreviation === value.abbreviation &&
                    option.fullName === value.fullName
                  }
                ></Autocomplete>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required
                  inputMode="numeric"
                  inputProps={{ type: "number" }}
                  value={`${
                    address && address?.zip_code == 0
                      ? undefined
                      : address?.zip_code
                  }`}
                  size="small"
                  id="zip"
                  label="Zip Code"
                  variant="filled"
                  onChange={(event) => {
                    if (address) {
                      setAddress({ ...address, zip_code: +event.target.value });
                      if (props.onChange) props.onChange(address);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item>
          <Typography color={"error"}>{errorText}</Typography>
        </Grid>
        {!props.noControls && (
          <Grid item container spacing={1} xs={12}>
            <Grid item>
              <Button onClick={onSubmit} variant="contained">
                Submit
              </Button>
            </Grid>
            {props.onCancel && (
              <Grid item>
                <Button onClick={onCancel} variant="outlined">
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AddressForm;
