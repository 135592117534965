import { AddCircleOutline, DeleteOutline, EditNote } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import AddressAPI from "../../../api/address";
import AddressForm from "../../../components/address/AddressForm";
import { useAlert } from "../../../lib/alert";
import { Organization } from "../../../model";
import {
  Address,
  AddressEntry,
  OrganizationAddressLabels,
} from "../../../model/Address";

interface ManageAddressProps {
  label: OrganizationAddressLabels;
  organization: Organization;
  customBlankText?: string;
}

const ManageOrganizationAddress: FC<ManageAddressProps> = ({
  label,
  organization,
  customBlankText,
}) => {
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [caution, setCaution] = useState<boolean>(false);
  const alert = useAlert();

  // Load address for this label
  useEffect(() => {
    AddressAPI.getAllOrganizationAddressEntries(organization.id)
      .then((result) => {
        const entry: AddressEntry | undefined = result.find(
          (addr) => addr.label == label,
        );
        if (entry) {
          AddressAPI.getAddressById(entry.address_id).then((result) => {
            if (result) {
              // Done laoding, address found
              setAddress(result);
              setLoading(false);
            } else {
              // Done loading, no address found
              setAddress(undefined);
              setLoading(false);
            }
          });
        } else {
          // Done loading, no address entry found
          setAddress(undefined);
          setLoading(false);
        }
      })
      .catch((e) => {
        alert.error("Couldn't load addresses!");
        console.error(e);
      });
  }, [organization, label]);

  if (loading) {
    // Skeleton while loading address
    return (
      <>
        <Grid container spacing={1} direction={"row"}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width={"50%"} height={48} />
          </Grid>
        </Grid>
      </>
    );
  }

  // Edit/Delete Address
  if (!loading && address) {
    return (
      <>
        {!open && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            minWidth={"25vw"}
            width={"50%"}
            boxShadow={"0px 0px 5px black"}
            borderRadius={1}
            p={2}
          >
            <Grid item xs={12} mb={3}>
              <Typography textAlign={"center"}>
                <b>{address.first_line}</b>
              </Typography>
              {address.second_line != "" && (
                <Typography textAlign={"center"}>
                  {address.second_line}
                </Typography>
              )}
              <Typography
                textAlign={"center"}
              >{`${address.city}, ${address.state}, ${address.zip_code}`}</Typography>
            </Grid>

            <Grid item container xs={12} px={2}>
              <Grid item xs={6} pl={2}>
                <Tooltip title="Edit Address">
                  <Button
                    fullWidth
                    sx={{ borderRadius: 0 }}
                    color="inherit"
                    onClick={() => {
                      setOpen(true);
                      setCaution(false);
                    }}
                  >
                    <EditNote />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item xs={6} pr={2}>
                {!caution ? (
                  <Tooltip title="Delete Address">
                    <Button
                      color="inherit"
                      fullWidth
                      sx={{ borderRadius: 0 }}
                      onClick={() => setCaution(true)}
                    >
                      <DeleteOutline />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ borderRadius: 0 }}
                    color="error"
                    onClick={() => {
                      AddressAPI.remove(address.id).catch((e) => {
                        alert.error("Error deleting address!");
                        console.error(e);
                      });
                      setAddress(undefined);
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {open && (
          <>
            <AddressForm
              current={address}
              onSubmit={(a) => {
                AddressAPI.update(address.id, a).catch((e) => {
                  alert.error("Error updating address!");
                  console.error(e);
                });
                setAddress(a);
                setOpen(false);
              }}
              onCancel={() => setOpen(false)}
            />
          </>
        )}
      </>
    );
  }

  // Create Address
  return (
    <>
      {!open && (
        <Grid
          container
          alignItems="center"
          justifyContent={"center"}
          borderRadius={1}
          width="50%"
          sx={{ border: "2px dashed gray" }}
        >
          <Grid item>
            <Typography>{customBlankText || "No address set!"}</Typography>
          </Grid>
          <Grid item m={1}>
            <Tooltip title="Create Address">
              <IconButton
                sx={{ display: "flex", aspectRatio: "1/1" }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <AddCircleOutline sx={{ mx: 1 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {open && (
        <AddressForm
          onCancel={() => setOpen(false)}
          onSubmit={(address) => {
            if (
              !(
                address.first_line == "" ||
                address.city == "" ||
                address.state == "" ||
                address.zip_code == 0
              )
            ) {
              setAddress(address);
              setCaution(false);
              AddressAPI.create(address, organization.id, label)
                .then((addr) => {
                  setAddress({ ...address, id: addr.id });
                })
                .catch((e) => {
                  alert.error("Unexpected error creating address!");
                  console.error(e);
                });
            }
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ManageOrganizationAddress;
