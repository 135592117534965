export enum NotificationType {
  PROCESS = "PROCESS",
  TASK = "TASK",
  ORGANIZATION = "ORGANIZATION",
  GENERAL = "GENERAL",
}

export enum NotificationStatus {
  PENDING = "PENDING",
  UNREAD = "UNREAD",
  READ = "READ",
  REMOVED = "REMOVED",
}

export type NotificationState = {
  notifications: Notification[];
  totalUnread: number;
  enabled: boolean;
};

export type NotificationData = Record<string, unknown>;

export default class Notification {
  public id = "";

  public org_id = "";

  public user_id = "";

  public subject_id = "";

  public title = "";

  public body = "";

  public data = {} as NotificationData;

  public created_at?: Date;

  public updated_at?: Date;

  public type = NotificationType.GENERAL;

  public status = NotificationStatus.PENDING;
}
