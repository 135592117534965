import {
  AssignmentIndOutlined,
  Check,
  CheckCircleOutline,
  CloudDownload,
  DeleteOutlined,
  HourglassBottom,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSearchIcon,
} from "@mui/x-data-grid";
import { AxiosRequestConfig } from "axios";
import { useAtom } from "jotai";
import debounce from "lodash.debounce";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Filter, PagedResponse, toFilter } from "../../../api";
import ProcessExecutions from "../../../api/process_executions";
import UserSelection from "../../../components/core/Selection/UserSelection";
import { DeleteDialog } from "../../../components/dialogs";
import AssignDialog from "../../../components/dialogs/AssignDialog";
import DatagridToolbar from "../../../components/elements/datagrid/DatagridToolbar";
import DatagridToolbarMenuButton from "../../../components/elements/datagrid/DatagridToolbarMenuButton";
import { TEST_ROW_CLASS_NAME } from "../../../components/elements/datagrid/StyledDataGrid";
import ToolbarButton from "../../../components/elements/datagrid/ToolbarButton";
import { PageHeader } from "../../../components/layout/page";
import TestModeStatus from "../../../components/layout/top-bar/components/TestModeStatus";
import TestModeToggle from "../../../components/layout/top-bar/components/TestModeToggle";
import useFeature from "../../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { RenderNextTask } from "../../../hooks/useGlobalRoleContext";
import { useGlobalUserContext } from "../../../hooks/useGlobalUserContext";
import { useAlert } from "../../../lib/alert";
import {
  createWebSocketMessage,
  WebSocketGlobalState,
} from "../../../lib/websocket/websocket";
import { Organization, User } from "../../../model";
import { ExecutionState, Process } from "../../../model/Process";
import {
  ExecutionType,
  ProcessExecution,
} from "../../../model/ProcessExecution";
import {
  WebSocketMessageType,
  WebSocketTrackingListen,
} from "../../../model/WebSocketMessage";
import downloadApiFile from "../../../util/downloadApiFile";
import environment from "../../../util/environment";
import { ChooseAccountDialog } from "../../accounts/components";
import NotificationMenu from "../../organization/components/NotificationMenu";
import OrganizationBillingBanner from "../../organization/components/OrganizationBillingBanner";
import messages from "../messages";
import { onProcessFilterAtom, testModeAtom } from "../state";
import ConfirmationModal from "./confirmation-modal/ConfirmationModal";
import ProcessQuickStart from "./process-quick-start/ProcessQuickStart";
import ProcessChip from "./ProcessChip";
import ProcessExecutionDataGrid, {
  DEFAULT_GRID_COLUMN_ASSIGNEE,
  DEFAULT_GRID_COLUMNS,
  ProcessExecutionDataGridProps,
} from "./ProcessExecutionDataGrid";
import ProcessGridSelector from "./ProcessGridSelector";
import ProcessPhaseChip from "./ProcessPhaseChip";

// TODO: Need to pull column definition out of the fields that the
// process_execution object exposes by API

type OrganizationProcessExecutionsProps = {
  currentOrganization?: Organization;
};

const POLL_DURATION = 30000;

const requestProcessExecutions = async (
  filters: Filter[],
  paging: { page: number; pageSize: number },
  org?: Organization,
  config: AxiosRequestConfig = {},
  threads: boolean = true,
  /*hiddenProcessMetas: string[],
  state: ExecutionState,
  userId?: string,
  org?: Organization,*/
) => {
  if (!org) {
    /*const response = await ProcessExecutions.byUserIdAndState(
      userId!,
      state,
      undefined,
      {
        offset: paging.page * paging.pageSize,
        pageSize: paging.pageSize,
      },
      hiddenProcessMetas,
    );
    return {
      total_count: response.length,
      data: response,
    };*/
    return {
      total_count: 0,
      data: [],
    };
  }
  if (threads) {
    return ProcessExecutions.byOrganizationThreads(
      org.id,
      filters,
      {
        offset: paging.page * paging.pageSize,
        pageSize: paging.pageSize,
      },
      config,
    );
  }
  return ProcessExecutions.byOrganizationId(
    org.id,
    filters,
    {
      offset: paging.page * paging.pageSize,
      pageSize: paging.pageSize,
    },
    config,
  );
};

const DOWNLOAD_PAGE_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 1000,
};

const OrganizationProcessExecutions: FC<OrganizationProcessExecutionsProps> = ({
  currentOrganization,
}) => {
  const [testMode] = useAtom(testModeAtom);
  const [, setOnFilter] = useAtom(onProcessFilterAtom);
  const v2 = useFeature("v2", true);
  const [searchParams] = useSearchParams();
  const queryParamsRead = useRef(false);
  const navigate = useNavigate();
  const intl = useIntl();
  const showPhases = useFeature("account.phases");
  const showAssigned = useFeature("process.assigned");
  const { organizations } = useGlobalOrganizationContext();
  // get the simplfied billing state

  const { user } = useGlobalUserContext();

  const { handleRejectionWithError, success, error } = useAlert();

  const [processExecutionPaginationModel, setProcessExecutionPaginationModel] =
    useState({
      page: 0,
      pageSize: 10,
    });

  const [selectedUser, setSelectedUser] = useState<User | null>();

  const [quickFilter, setQuickFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState<
    "all" | "inProgress" | "completed"
  >("inProgress");

  const viewAccountColumn = useFeature("process.accountView");
  // useEffect to clear filters on currentOrganization change
  useEffect(() => {
    setGridFilters({ items: [] });
    setQuickFilter("all");
    setStatusFilter("all");
    setSearchOpen(false);
    setSelectedUser(null);
    // depend the processExecutionPaginationModel on the pageSize
    setProcessExecutionPaginationModel({
      page: 0,
      pageSize: processExecutionPaginationModel.pageSize,
    });
  }, [currentOrganization]);

  const gridColumns = useMemo(() => {
    const gridColumns: GridColDef[] = [
      DEFAULT_GRID_COLUMNS.TITLE,
      DEFAULT_GRID_COLUMNS.STATUS,
      DEFAULT_GRID_COLUMNS.CHAT,
    ];
    if (viewAccountColumn) {
      gridColumns.push(DEFAULT_GRID_COLUMNS.ACCOUNT);
    }
    // splices column in after phase to show process name if feature flag is used
    if (showPhases) {
      gridColumns.push({
        field: "phase",
        headerName: "Phase",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        headerClassName: "process-grid-header",
        renderCell: (params: GridRenderCellParams) => {
          return <ProcessPhaseChip {...params.row} />;
        },
      });
    }
    if (localStorage.getItem("runway.singleProcess")) {
      gridColumns.push({
        field: "rootProcess",
        headerName: "Process",
        sortable: true,
        disableColumnMenu: true,
        flex: 1.5,
        minWidth: 100,
        headerClassName: "process-grid-header",
        renderCell: (params: GridRenderCellParams) => {
          return <ProcessGridSelector {...params.row} />;
        },
      });
    }

    if (v2) {
      if (showAssigned) {
        gridColumns.push(DEFAULT_GRID_COLUMNS.OWNER);
      }
    } else {
      gridColumns.push(DEFAULT_GRID_COLUMNS.PROGRESS);
      // splices next task column in since a separate method is used
      gridColumns.push({
        field: "nextTask",
        headerName: "Next Task",
        sortable: false,
        disableColumnMenu: true,
        flex: 2,
        minWidth: 150,
        headerClassName: "process-grid-header",
        renderCell: RenderNextTask,
      });
      gridColumns.push(DEFAULT_GRID_COLUMNS.DUE_DATE);
      gridColumns.push(DEFAULT_GRID_COLUMN_ASSIGNEE(user));
    }
    gridColumns.push(DEFAULT_GRID_COLUMNS.UPDATED);
    return gridColumns;
  }, [viewAccountColumn, showPhases, v2, showAssigned, user]);

  const reloadPollTimer = useRef<NodeJS.Timeout | undefined>(undefined);

  const [gridFilters, setGridFilters] = useState<GridFilterModel>({
    items: [],
  });
  // all Process Executions that are available
  const [processExecutions, setProcessExecutions] = useState<
    Array<ProcessExecution>
  >([]);
  const [totalProcessExecutions, setTotalProcessExecutions] =
    useState<number>(0);
  const [processExecutionsLoaded, setProcessExecutionsLoaded] =
    useState<boolean>(false);

  const [processExecutionSelectionModel, setProcessExecutionSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [numRowsSelected, setNumRowsSelected] = useState<number>(0);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);

  const [chooseAccountDialogOpen, setChooseAccountDialogOpen] = useState(false);

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  // determine if there are selected processes that need to be completed
  const [selectedProcessesToComplete, setSelectedProcessesToComplete] =
    useState<number>(0);

  const executionMsg = intl.formatMessage(messages.execution.props);
  const executionsMsg = intl.formatMessage(messages.executions.props);
  const inProgressExecutionsMsg = intl.formatMessage(
    messages.inProgressExecutions.props,
  );
  const completedExecutionsMsg = intl.formatMessage(
    messages.completedExecutions.props,
  );

  useEffect(() => {
    const trackingData: WebSocketTrackingListen = {
      type: "organization",
      subject_id: currentOrganization?.id ?? "",
    };

    const webSocketMessageToSend = createWebSocketMessage(
      WebSocketMessageType.TRACKING,
      trackingData,
    );

    WebSocketGlobalState.getWebSocket()?.send(
      JSON.stringify(webSocketMessageToSend),
    );
  }, [currentOrganization]);

  // Update Navigation on filter or page change
  useEffect(() => {
    const filters = gridFilters.items
      .filter((filterItem) => {
        return filterItem.field === "assignee" || filterItem.field === "state";
      })
      .map((filterItem) => {
        if (filterItem.field === "assignee") {
          if (filterItem.value === undefined) {
            return "assignee=unassigned";
          } else if (filterItem.value === user.id) {
            return "assignee=me";
          } else if (filterItem.value) {
            return "assignee=search&userID=" + filterItem.value;
          }
        }
        if (filterItem.field === "state") {
          return `status=${filterItem.value}`;
        }
      })
      .join("&");
    const page =
      processExecutionPaginationModel.page > 0
        ? `page=${processExecutionPaginationModel.page}`
        : undefined;
    const query = [filters, page].filter((part) => !!part).join("&");
    navigate(query ? `?${query}` : "", {
      replace: true,
    });
  }, [processExecutionPaginationModel, gridFilters, navigate, user.id]);

  const addGridFilter = useCallback((filter: GridFilterItem) => {
    // Replace existing filter if any with new filter
    setGridFilters((filters) => {
      const filtersWithoutExistingFieldFilter = filters.items.filter(
        (f) => f.field !== filter.field,
      );
      const removeFilter =
        filter.operator === "equals" && filter.value === undefined;
      return {
        ...filters,
        items: removeFilter
          ? filtersWithoutExistingFieldFilter
          : [...filtersWithoutExistingFieldFilter, filter],
      };
    });
    // Reset pagination
    setProcessExecutionPaginationModel((pageModel) => ({
      ...pageModel,
      page: 0,
    }));
  }, []);

  const handleQuickFilterChange = useCallback(
    (newFilter: string, userId?: string | null) => {
      setQuickFilter(newFilter);
      const assigneeFilter: GridFilterItem = {
        field: "assignee",
        operator: "equals",
        value: undefined,
      };
      if (showAssigned) {
        if (newFilter === "me") {
          assigneeFilter.value = user.id;
        } else if (newFilter === "unassigned") {
          assigneeFilter.operator = "isEmpty";
        } else if (newFilter === "search" && userId) {
          assigneeFilter.value = userId;
        }
        addGridFilter(assigneeFilter);
      }
    },
    [addGridFilter, user.id, showAssigned],
  );

  const requestFilteredProcessExecutions = useCallback(
    (download = false): Promise<PagedResponse<ProcessExecution>> => {
      const config: AxiosRequestConfig = download ? { format: "xlsx" } : {};
      const hiddenProcessMetas =
        organizations.length > 0 ? environment.hiddenProcessMetaIds : undefined;
      const filters: Filter[] = hiddenProcessMetas
        ? [
            {
              field: "process_id",
              operator: "not in",
              value: hiddenProcessMetas.join(","),
            },
          ]
        : [];

      // if the status filter is set to something other than "all", add the filter to the request
      if (statusFilter !== "all") {
        filters.push({
          field: "state",
          operator: "=",
          value: statusFilter === "inProgress" ? "IN_PROGRESS" : "COMPLETED",
        });
      }

      gridFilters.items.forEach((gridFilter) => {
        filters.push(toFilter(gridFilter));
        if (gridFilter.field === "assignee") {
          filters.push(
            toFilter({
              ...gridFilter,
              field: "owner",
            }),
          );
          filters.push(
            toFilter({
              ...gridFilter,
              field: "next_task_assignee",
            }),
          );
        }
      });

      return requestProcessExecutions(
        filters,
        download ? DOWNLOAD_PAGE_MODEL : processExecutionPaginationModel,
        currentOrganization,
        config,
        v2,
      ).then(
        (response) => {
          if (!download) {
            const loadedExecutions = response?.data ?? [];
            setProcessExecutions(loadedExecutions);
            setTotalProcessExecutions(response.total_count);
            setProcessExecutionsLoaded(true);
          }
          return response;
        },
        handleRejectionWithError(`Failed to load ${executionsMsg}`),
      );
    },
    [
      organizations.length,
      gridFilters.items,
      processExecutionPaginationModel,
      currentOrganization,
      v2,
      handleRejectionWithError,
      executionsMsg,
      statusFilter,
    ],
  );

  const debouncedReload = useMemo(
    () =>
      debounce(
        () => {
          if (!user.id) {
            return;
          }
          requestFilteredProcessExecutions().then(() =>
            debouncedReload.cancel(),
          );
        },
        5000,
        { leading: true },
      ),
    [requestFilteredProcessExecutions, user.id],
  );

  const reload = useCallback(debouncedReload, [debouncedReload]);

  useEffect(() => {
    // default organization & user is empty
    // the result is that "fetchRoleIdsForUser" throws an Unauthorized exception
    // which leads to poor UX
    if (reloadPollTimer.current) {
      clearInterval(reloadPollTimer.current);
    }
    const timer = setInterval(() => reload(), POLL_DURATION);
    reloadPollTimer.current = timer;
    reload();
    setOnFilter((process) => {
      // Filter process executions by process meta
      addGridFilter({
        field: "process_id",
        operator: "equals",
        value: process.id,
        process: process,
      } as unknown as GridFilterItem);
    });
    return () => {
      clearInterval(reloadPollTimer.current);
    };
  }, [addGridFilter, currentOrganization?.id, reload, setOnFilter]);

  useEffect(() => {
    if (queryParamsRead.current) {
      return;
    }
    const assignee = searchParams.get("assignee");
    const status = searchParams.get("status");
    const page = searchParams.get("page");
    const searchId = searchParams.get("userID");
    if (assignee) {
      handleQuickFilterChange(assignee, searchId);
    }
    if (status) {
      setStatusFilter(status as typeof statusFilter);
    }
    if (searchId) {
      setSearchOpen(true);
    }
    if (page) {
      const pageIndex = parseInt(page);
      setProcessExecutionPaginationModel((pageModel) => ({
        ...pageModel,
        page: pageIndex,
      }));
    }
    queryParamsRead.current = true;
  }, [gridFilters, handleQuickFilterChange, reloadPollTimer, searchParams]);

  useEffect(() => {
    const inProgressProcessExecutions = processExecutionSelectionModel.filter(
      (processExecutionId) => {
        const processExecution = processExecutions.find(
          (pe) => pe.id == processExecutionId,
        );
        if (!processExecution) return false;

        return processExecution.state == ExecutionState.InProgress;
      },
    );

    setSelectedProcessesToComplete(inProgressProcessExecutions.length);
  }, [processExecutionSelectionModel, processExecutions]);

  const assignMemberBtn = (
    <ToolbarButton
      dataCy="set-execution-account-btn"
      key="assignMemberBtn"
      startIcon={<AssignmentIndOutlined />}
      onClick={() => setAssignDialogOpen(true)}
      textContent="Assign"
    />
  );

  const deleteBtn = (
    <ToolbarButton
      dataCy="delete-execution-btn"
      key="deleteBtn"
      startIcon={<DeleteOutlined />}
      onClick={() => setDeleteDialogOpen(true)}
      textContent="Delete"
    />
  );

  const setAccountBtn = (
    <ToolbarButton
      dataCy="set-execution-account-btn"
      key="setAccountBtn"
      startIcon={<AccountCircleIcon />}
      onClick={() => setChooseAccountDialogOpen(true)}
      textContent="Set Account"
    />
  );

  const completeBtn = (
    <ToolbarButton
      dataCy="complete-execution-btn"
      key="completeBtn"
      startIcon={<CheckCircleOutline />}
      onClick={() => setCompleteDialogOpen(true)}
      textContent="Complete"
    />
  );

  const [searchOpen, setSearchOpen] = useState(false);

  const processFilter = gridFilters.items.find(
    (item) => item.field === "process_id",
  ) as (GridFilterItem & { process: Process }) | undefined;

  const filterStatusBtnGrp = (
    <Box
      key="filterStatusBtnGrp"
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "12px",
        alignItems: "center",
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={statusFilter}
        size="small"
        exclusive
        onChange={(evt, value) => {
          if (value === null) {
            value = "all";
          }
          setStatusFilter(value as typeof statusFilter);
        }}
        aria-label="Filter"
      >
        <ToggleButton size="small" value="all">
          All
        </ToggleButton>

        <ToggleButton size="small" value="inProgress">
          <HourglassBottom />
          {inProgressExecutionsMsg}
        </ToggleButton>
        <ToggleButton size="small" value="completed">
          <Check />
          {completedExecutionsMsg}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  const filterBtnGrp = (
    <Box
      key="filterBtnGrp"
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "12px",
        alignItems: "center",
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={quickFilter}
        size="small"
        exclusive
        onChange={(evt, value) => {
          if (value === null) {
            value = "all";
          }
          if (value != "search") {
            setSearchOpen(false);
            handleQuickFilterChange(value);
          } else {
            handleQuickFilterChange(value, selectedUser?.id);
          }
        }}
        aria-label="Filter"
      >
        <ToggleButton size="small" value="all">
          All
        </ToggleButton>
        <ToggleButton size="small" value="unassigned">
          Unassigned
        </ToggleButton>
        <ToggleButton size="small" value="me">
          My {executionsMsg}
        </ToggleButton>
        <ToggleButton
          size="small"
          value="search"
          sx={{ mr: 1 }}
          onClick={() => setSearchOpen(!searchOpen)}
        >
          <GridSearchIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <Box sx={{ minWidth: "300px" }} hidden={!searchOpen}>
        <UserSelection
          organizationId={currentOrganization?.id}
          AutoCompleteProps={{
            size: "small",
            renderInput: (params) => (
              <TextField {...params} label={"Search Assignees"} />
            ),
            multiple: false,
            value: () => {
              return selectedUser ?? null;
            },
            onChange: (_, user) => {
              setSelectedUser(user);
              if (user) {
                handleQuickFilterChange("search", user.id);
              } else {
                handleQuickFilterChange("search");
              }
            },
          }}
        />
      </Box>
      {processFilter && (
        <ProcessChip
          name={processFilter.process?.name ?? ""}
          icon={processFilter.process?.icon ?? ""}
          onDelete={() => {
            setGridFilters((filters) => ({
              ...filters,
              items: filters.items.filter(
                (filterItem) => filterItem.field !== "process_id",
              ),
            }));
          }}
        />
      )}
    </Box>
  );

  const countMsg = useMemo(() => {
    const testExecutions = processExecutions.filter((e) => {
      if (e.execution_type === ExecutionType.Test) return e;
    });

    const nonTestCount = totalProcessExecutions - testExecutions.length;

    const countMessage = `${nonTestCount} ${
      nonTestCount === 1 ? executionMsg : executionsMsg
    }`;

    if (testMode) {
      return `${countMessage} and ${testExecutions.length} ${
        testExecutions.length == 1 ? " Test" : " Tests"
      }`;
    }
    return `${countMessage}`;
  }, [
    executionMsg,
    executionsMsg,
    processExecutions,
    testMode,
    totalProcessExecutions,
  ]);

  const assignMember = (id: string) => {
    Promise.all(
      processExecutionSelectionModel.map((processExecutionId) => {
        return ProcessExecutions.assignProcessExecution(
          processExecutionId as string,
          id,
        );
      }),
    )
      .then(() => {
        reload();
        success("Assignment successful");
      })
      .catch(() => {
        error("Some assignments failed");
      });
  };
  const commonProps: Partial<ProcessExecutionDataGridProps> = {
    paginationMode: "server",
    rowSelectionModel: processExecutionSelectionModel,
    slots: { toolbar: DatagridToolbar },
    getRowClassName: (params) => {
      return params.row.execution_type == ExecutionType.Test
        ? TEST_ROW_CLASS_NAME
        : "";
    },
    /* FILTERING */
    filterModel: gridFilters,
    filterMode: "server",
    columnVisibilityModel: {
      id: false,
    },
    /* EDITING */
    onCellEditStart: () => {
      // prevent refresh
      clearInterval(reloadPollTimer.current);
    },
    onCellEditStop: () => {
      const timer = setInterval(() => reload(), POLL_DURATION);
      reloadPollTimer.current = timer;
    },
    onRowSelectionModelChange: (newSelectionModel) => {
      setProcessExecutionSelectionModel(newSelectionModel);
      setNumRowsSelected(newSelectionModel.length);
    },
  };
  const moreMenuBtn = (
    <DatagridToolbarMenuButton
      items={[
        {
          label: "Export",
          icon: CloudDownload,
          onClick: () => {
            const dateString = new Date().toISOString().slice(0, 10);
            requestFilteredProcessExecutions(true).then(
              (blob) =>
                downloadApiFile(
                  blob as unknown as Blob,
                  `${
                    currentOrganization?.key ?? currentOrganization?.name
                  }-${executionsMsg.toLocaleLowerCase()}-${dateString}.xlsx`,
                ),
              handleRejectionWithError(
                `Failed to download ${executionsMsg} file`,
              ),
            );
          },
        },
        {
          component: ({ onClick }) => (
            <TestModeToggle
              objectTitle={executionsMsg}
              variant="menuItem"
              onUpdate={() => {
                reload();
                onClick();
              }}
            />
          ),
        },
      ]}
    />
  );

  return (
    <>
      <PageHeader title={`${executionsMsg}`}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="h1">{executionsMsg}</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <ProcessQuickStart
              organization={currentOrganization}
              lastProcessExecution={processExecutions[0]}
            />
            {currentOrganization && (
              <NotificationMenu organization={currentOrganization} />
            )}
          </Box>
        </Box>
      </PageHeader>

      <OrganizationBillingBanner />
      <Box
        flex={1}
        overflow={"hidden"}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      >
        {processExecutionsLoaded && (
          <ProcessExecutionDataGrid
            slotProps={{
              toolbar: {
                countMsg: countMsg,
                numSelected: numRowsSelected,
                selectedBtns: (
                  <>
                    {statusFilter !== "completed" &&
                      selectedProcessesToComplete > 0 &&
                      completeBtn}
                    {assignMemberBtn}
                    {viewAccountColumn && setAccountBtn}
                    {deleteBtn}
                  </>
                ),
                defaultBtns: (
                  <>
                    {showAssigned && filterBtnGrp}
                    {filterStatusBtnGrp}
                    {moreMenuBtn}
                  </>
                ),
                testModeToggle: <TestModeStatus objectTitle={executionsMsg} />,
              },
            }}
            columns={gridColumns}
            rows={processExecutions}
            paginationModel={processExecutionPaginationModel}
            rowCount={totalProcessExecutions}
            onPaginationModelChange={(paginationModel) => {
              if (paginationModel.pageSize == 0) {
                // do nothing
                return;
              }
              setProcessExecutionPaginationModel(paginationModel);
            }}
            {...commonProps}
          />
        )}
      </Box>
      {completeDialogOpen && (
        <ConfirmationModal
          confirmationData={{
            title:
              processExecutionSelectionModel.length > 1
                ? `Complete ${executionsMsg}`
                : `Complete ${executionMsg}`,
            body:
              processExecutionSelectionModel.length === 1
                ? `Are you sure you want to complete this ${executionMsg}?`
                : `Are you sure you want to complete these ${selectedProcessesToComplete} ${executionsMsg}?`,
            cancelLabelText: "Cancel",
            continueLabelText: "Mark Complete",
          }}
          openModal={completeDialogOpen}
          handleCloseModal={() => setCompleteDialogOpen(false)}
          executeModal={() => {
            processExecutionSelectionModel.map((processExecutionId) =>
              ProcessExecutions.complete(processExecutionId as string).then(
                () => {
                  reload();
                  success(
                    "Successfully completed " +
                      executionsMsg.toLocaleLowerCase(),
                  );
                },
                handleRejectionWithError(
                  "Failed to complete " + executionsMsg.toLocaleLowerCase(),
                ),
              ),
            );
          }}
        />
      )}
      {chooseAccountDialogOpen && currentOrganization && (
        <ChooseAccountDialog
          organization={currentOrganization}
          open={chooseAccountDialogOpen}
          onSelect={async (account) => {
            const promises = Promise.all(
              processExecutionSelectionModel.map((processExecutionId) =>
                ProcessExecutions.update(processExecutionId as string, {
                  subject_id: account.id,
                }),
              ),
            ).then(
              () => {
                reload();
                success(
                  `Successfully changed account to ${account.account_name} for ` +
                    executionsMsg.toLocaleLowerCase(),
                );
              },
              handleRejectionWithError(
                "Failed to assign account to " +
                  executionsMsg.toLocaleLowerCase(),
              ),
            );
            await promises;
            // TODO - set account on selected process executions
          }}
          onClose={() => setChooseAccountDialogOpen(false)}
        />
      )}
      {deleteDialogOpen && (
        <DeleteDialog
          title={
            processExecutionSelectionModel.length > 1
              ? `Delete ${executionsMsg}`
              : `Delete ${executionMsg}`
          }
          question={`Are you sure you want to delete ${
            processExecutionSelectionModel.length > 1
              ? `these ${executionsMsg.toLocaleLowerCase()}`
              : `this ${executionMsg.toLocaleLowerCase()}`
          }? The data will not be recoverable and this procedure cannot be undone.`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={() => {
            return Promise.all(
              processExecutionSelectionModel.map((processExecutionId) =>
                ProcessExecutions.deleteProcessExecution(
                  processExecutionId as string,
                ),
              ),
            ).then(
              () => {
                reload();
                success(
                  "Successfully deleted " + executionsMsg.toLocaleLowerCase(),
                );
              },
              handleRejectionWithError(
                "Failed to delete all " + executionsMsg.toLocaleLowerCase(),
              ),
            );
          }}
        />
      )}
      {assignDialogOpen && currentOrganization && (
        <AssignDialog
          open={assignDialogOpen}
          orgId={currentOrganization.id}
          onClose={() => setAssignDialogOpen(false)}
          onConfirm={(userId) => assignMember(userId)}
        />
      )}
    </>
  );
};

export default OrganizationProcessExecutions;
