import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { OrganizationAddressLabels } from "../../../model/Address";
import ManageAddress from "../components/ManageAddress";
import { useUpdateOrganizationProps } from "../components/UpdateOrganizationContext";

const OrganizationAddressSettings: FC<unknown> = () => {
  const { organization } = useUpdateOrganizationProps();

  if (organization)
    return (
      <Grid container spacing={3} direction={"column"}>
        <Grid item>
          <Typography variant="h2">Organization Addresses</Typography>
        </Grid>

        <Grid item container direction={"column"} spacing={2} mb={2}>
          <Grid item>
            <Typography>Primary Address</Typography>
          </Grid>

          <Grid item>
            <ManageAddress
              label={OrganizationAddressLabels.PRIMARY}
              organization={organization}
              customBlankText="No primary address!"
            />
          </Grid>
        </Grid>

        <Grid item container direction={"column"} spacing={2}>
          <Grid item>
            <Typography>Billing Address</Typography>
          </Grid>

          <Grid item>
            <ManageAddress
              label={OrganizationAddressLabels.BILLING}
              organization={organization}
              customBlankText="No billing address!"
            />
          </Grid>
        </Grid>

        <Grid item container direction={"column"} spacing={2}>
          <Grid item>
            <Typography>Secondary Address</Typography>
          </Grid>

          <Grid item>
            <ManageAddress
              label={OrganizationAddressLabels.SECONDARY}
              organization={organization}
              customBlankText="No secondary address!"
            />
          </Grid>
        </Grid>
      </Grid>
    );
};

export { OrganizationAddressSettings };
