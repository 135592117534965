/**
 * This global context is used to store / retrieve current organization data
 */

import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { Organization } from "../model";
import { Account } from "../model/Account";
import OrganizationBillingState from "../model/OrganizationBillingState";

export type GlobalOrganizationContentType = {
  organization?: Organization;
  organizationBillingState?: OrganizationBillingState;
  organizations: Array<Organization>;
  organizationBillingStateLoaded: boolean;
  organizationsLoaded: boolean;
  accountsLoaded: boolean;
  setOrganization: (organization?: Organization) => void;
  refreshOrganizations: () => Promise<Organization[]>;
  refreshOrganization: (
    organization_id: string,
  ) => Promise<Organization | null>;
  accountListIsMemberOf: Array<Account>;
  setAccountListIsMemberOf: Dispatch<SetStateAction<Array<Account>>>;
  isMember: (orgId?: string) => boolean;
};

export const GlobalOrganizationContext =
  createContext<GlobalOrganizationContentType>({
    organization: {
      id: "",
      name: "",
      key: "",
      owner_id: "",
      description: "",
    },
    organizations: [],
    organizationBillingStateLoaded: false,
    organizationsLoaded: false,
    accountsLoaded: false,
    setOrganization: () => {
      return;
    },
    refreshOrganizations: () => Promise.resolve([]),
    refreshOrganization: () => Promise.resolve(null),
    accountListIsMemberOf: [],
    setAccountListIsMemberOf: () => {
      return;
    },
    isMember: () => {
      return false;
    },
  });

export const useGlobalOrganizationContext = () =>
  useContext(GlobalOrganizationContext);
