import {
  Notifications,
  NotificationsActive,
  NotificationsNone,
  NotificationsOff,
  NotificationsPaused,
  Settings,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { NotificationAPI } from "../../../api";
import PATHS from "../../../components/navigation/_paths";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import eventEmitter from "../../../lib/event";
import {
  getNotificationPermission,
  requestNotificationPermissionIfRequired,
} from "../../../lib/notification";
import { Organization } from "../../../model";
import Notification, {
  NotificationStatus,
  NotificationType,
} from "../../../model/Notification";
import { WebSocketNotificationData } from "../../../model/WebSocketMessage";
import NotificationMenuItem from "./NotificationMenuItem";

export enum NotificationExpandVariant {
  Default,
  Bottom,
  BottomLeft,
  BottomRight,
  Top,
  TopLeft,
  TopRight,
}

export enum NotificationScope {
  AllUnread,
  All,
  OrganizationUnread,
  Organization,
}

const VARIANTS = {
  [NotificationExpandVariant.Default]: {
    position: "absolute",
    top: 50,
    right: 0,
  },
  [NotificationExpandVariant.Bottom]: {
    position: "absolute",
    top: 50,
    right: 0,
    transform: "translateX(50%)",
  },
  [NotificationExpandVariant.BottomLeft]: {
    position: "absolute",
    top: 50,
    left: 0,
  },
  [NotificationExpandVariant.BottomRight]: {
    position: "absolute",
    top: 50,
    right: 0,
  },
  [NotificationExpandVariant.Top]: {
    position: "absolute",
    bottom: 50,
    right: 0,
    transform: "translateX(50%)",
  },
  [NotificationExpandVariant.TopLeft]: {
    position: "absolute",
    bottom: 50,
    left: 0,
  },
  [NotificationExpandVariant.TopRight]: {
    position: "absolute",
    bottom: 50,
    right: 0,
  },
};

const NotificationMenu = ({
  organization,
  variant = NotificationExpandVariant.Default,
}: {
  organization: Organization;
  variant?: NotificationExpandVariant;
}) => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  const [notificationsEnabled, setNotificationsEnabled] =
    useState<boolean>(true);

  useEffect(() => {
    NotificationAPI.getNotificationsByUserAndOrganization(
      currentUserId(),
      organization.id,
    ).then((notificationState) => {
      setNotifications(notificationState.notifications);
      setNotificationsEnabled(notificationState.enabled);
    });
  }, [organization.id]);

  const [showNotifications, setShowNotifications] = useState(false);

  const { error } = useAlert();

  const navigate = useNavigate();

  const hasPermission = useState<boolean>(
    getNotificationPermission() === "granted",
  );

  const requestPermission = async () => {
    requestNotificationPermissionIfRequired();
  };

  const { refreshOrganization } = useGlobalOrganizationContext();

  const unreadNotifications = useMemo(
    () =>
      notifications.filter(
        (notification) => notification.status == NotificationStatus.UNREAD,
      ),
    [notifications],
  );

  const notificationStateIcon = useMemo(() => {
    if (!notificationsEnabled) {
      return <NotificationsPaused fontSize="medium" />;
    }

    if (!hasPermission) {
      return <NotificationsOff fontSize="medium" />;
    }

    if (notifications.length == 0) {
      return <NotificationsNone fontSize="medium" />;
    }

    if (unreadNotifications.length == 0) {
      return <Notifications fontSize="medium" />;
    }

    return <NotificationsActive fontSize="medium" />;
  }, [
    notificationsEnabled,
    hasPermission,
    notifications.length,
    unreadNotifications.length,
  ]);

  useEffect(() => {
    eventEmitter.on(
      "notification:statusChange:read",
      (notificationId: string) => {
        const updatedNotifications = notifications.map((notification) => {
          if (notification.id === notificationId) {
            return {
              ...notification,
              status: NotificationStatus.READ,
            };
          }
          return notification;
        });

        setNotifications(updatedNotifications);
        refreshOrganization(organization.id);
      },
    );

    return () => {
      eventEmitter.off("notification:statusChange:read");
    };
  });

  const handleNotification = useCallback(
    (notificationData: WebSocketNotificationData) => {
      refreshOrganization(organization.id);

      // if received notification is not for the current organization
      // don't show in this menu, but signal a refresh for the organization targeted
      if (notificationData.organization_id != organization.id) {
        return;
      }

      const tempNotification: Notification = {
        org_id: notificationData.organization_id,
        user_id: currentUserId(),
        id: notificationData.id,
        title: notificationData.title,
        body: notificationData.body,
        data: {
          process_id: notificationData.process_id,
        },
        subject_id: notificationData.process_execution_id || "",
        created_at: new Date(),
        updated_at: new Date(),
        type:
          (notificationData.type?.toUpperCase() as NotificationType) ||
          NotificationType.GENERAL,
        status: NotificationStatus.UNREAD,
      };

      setNotifications([tempNotification, ...notifications]);
    },
    [notifications, organization.id, refreshOrganization],
  );

  useEffect(() => {
    eventEmitter.on(
      "websocket:notification",
      (notificationData: WebSocketNotificationData) => {
        // check organization id
        if (!notificationData.organization_id) {
          return;
        }

        handleNotification(notificationData);
      },
    );

    return () => {
      eventEmitter.off("websocket:notification");
    };
  }, [handleNotification]);

  const setNotificationsToStatus = useCallback(
    async (status: NotificationStatus) => {
      await NotificationAPI.markAllNotificationsStatus(
        currentUserId(),
        status,
        organization.id,
      ).catch(() => {
        error("Failed to save notification status");
      });

      if (status === NotificationStatus.REMOVED) {
        setNotifications([]);
      } else {
        const updatedNotifications = notifications.map((notification) => ({
          ...notification,
          status,
        }));
        setNotifications(updatedNotifications);
        refreshOrganization(organization.id);
      }
    },
    [error, notifications, organization.id, refreshOrganization],
  );

  return (
    <>
      <Box position={"relative"}>
        <Box sx={{ ml: 1 }}>
          <Tooltip title={`Notifications (${notifications.length})`}>
            <Paper elevation={1} sx={{ borderRadius: "50%" }}>
              <IconButton
                onClick={() => {
                  requestPermission();
                  setShowNotifications(!showNotifications);
                }}
              >
                {notificationStateIcon}
                <Badge
                  badgeContent={unreadNotifications.length}
                  sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                  }}
                  color="primary"
                />
              </IconButton>
            </Paper>
          </Tooltip>
        </Box>
        <Box zIndex={10} width={400} sx={{ ...VARIANTS[variant] }}>
          <Collapse in={showNotifications}>
            <Box
              sx={{
                borderRadius: 4,
                backgroundColor: (theme) =>
                  `${theme.palette.background.dark_transparent}`,
                color: (theme) => theme.palette.background.paper,
                backdropFilter: "blur(2px)",
              }}
            >
              <Box
                p={1.5}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.dark,
                  borderRadius: 4,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  pt: 2,
                }}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box mx={1}>
                  <Typography variant="body2" fontWeight={"bold"} fontSize={20}>
                    Notifications{" "}
                    {unreadNotifications.length > 0 &&
                      ` (${unreadNotifications.length})`}
                  </Typography>
                  {!notificationsEnabled && (
                    <Typography textAlign={"center"} fontSize={12}>
                      Notifications are disabled
                    </Typography>
                  )}
                </Box>

                <Tooltip title="Notification Preferences">
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.background.paper,
                    }}
                    onClick={() => {
                      navigate(PATHS.PROFILE_PREFERENCES.link);
                    }}
                  >
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  maxHeight: 300,
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                {notifications.length === 0 && (
                  <Box p={2}>
                    <Typography variant="body2" textAlign={"center"}>
                      No notifications
                    </Typography>
                  </Box>
                )}
                {notifications.map((notification, index) => (
                  <>
                    {index > 0 && (
                      <Divider
                        sx={{
                          mx: 2,
                          borderColor: (theme) =>
                            theme.palette.background.paper,
                        }}
                      />
                    )}

                    <NotificationMenuItem
                      notification={notification}
                      onDelete={() => {
                        // remove from state
                        setNotifications(
                          notifications.filter((n) => n.id !== notification.id),
                        );
                        refreshOrganization(organization.id);
                      }}
                      onChangeStatus={(status: NotificationStatus) => {
                        // update the notification status
                        const updatedNotification = {
                          ...notification,
                          status,
                        };
                        setNotifications(
                          notifications.map((n) =>
                            n.id === notification.id ? updatedNotification : n,
                          ),
                        );
                        refreshOrganization(organization.id);
                      }}
                    />
                  </>
                ))}
              </Box>

              {notifications.length > 0 && (
                <Box
                  p={1.5}
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.dark,
                    borderRadius: 4,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Button
                    variant="text"
                    onClick={() =>
                      setNotificationsToStatus(NotificationStatus.READ)
                    }
                    sx={{
                      color: (theme) => theme.palette.background.paper,
                      ml: 1,
                    }}
                    size="small"
                  >
                    Read All
                  </Button>
                  <Button
                    variant="text"
                    onClick={() =>
                      setNotificationsToStatus(NotificationStatus.REMOVED)
                    }
                    sx={{
                      color: (theme) => theme.palette.background.paper,
                      ml: 1,
                    }}
                    size="small"
                  >
                    Delete All
                  </Button>
                </Box>
              )}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default NotificationMenu;
