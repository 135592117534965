import {
  Address,
  AddressEntry,
  OrganizationAddressLabels,
} from "../model/Address";
import API from "./base";

const createWithEntry = (
  address: Address,
  addressEntry: AddressEntry,
): Promise<Address> => {
  return new Promise((resolve, reject) => {
    const payload = {
      addressEntry: addressEntry,
      address: address,
    };
    API.post("/address/create", payload).then(
      (result) => resolve(result.data.payload as Address),
      reject,
    );
  });
};

const create = (
  address: Address,
  organization_id: string,
  label: OrganizationAddressLabels,
  user_id?: string,
): Promise<Address> => {
  const entry: AddressEntry = {
    organization_id: organization_id,
    user_id: user_id || "",
    address_id: "",
    label: label,
    id: "",
  };
  return new Promise((resolve, reject) => {
    const payload = {
      addressEntry: entry,
      address: address,
    };
    API.post("/address/create", payload).then(
      (result) => resolve(result.data.payload as Address),
      reject,
    );
  });
};

const getAddressById = (address_id: string): Promise<Address> => {
  return new Promise((resolve, reject) => {
    API.get("address/" + address_id).then(
      (result) => resolve(result.data.payload as Address),
      reject,
    );
  });
};

// Gives both org user and global addresses
const getAllOrganizationAddressEntries = (
  organization_id: string,
): Promise<Array<AddressEntry>> => {
  return new Promise((resolve, reject) => {
    API.get("address/organization/" + organization_id + "/all").then(
      (result) => resolve(result.data.payload as Array<AddressEntry>),
      reject,
    );
  });
};

const getGlobalOrganizationAddresses = (
  organization_id: string,
): Promise<Array<AddressEntry>> => {
  return new Promise((resolve, reject) => {
    API.get("address/organization/" + organization_id).then(
      (result) => resolve(result.data.payload as Array<AddressEntry>),
      reject,
    );
  });
};

const getOrganizationUserAddresses = (
  organization_id: string,
  user_id: string,
): Promise<Array<AddressEntry>> => {
  return new Promise((resolve, reject) => {
    API.get(
      "address/organization/" + organization_id + "/user/" + user_id,
    ).then(
      (result) => resolve(result.data.payload as Array<AddressEntry>),
      reject,
    );
  });
};

const remove = (addressId: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    API.delete("/address/" + addressId).then(
      (result) => resolve(result.data.payload as string),
      reject,
    );
  });
};

const update = (addressId: string, address: Address): Promise<Address> => {
  return new Promise((resolve, reject) => {
    API.post("/address/update/" + addressId, address).then(
      (result) => resolve(result.data.payload as Address),
      reject,
    );
  });
};

export default {
  create,
  remove,
  update,
  createWithEntry,
  getAddressById,
  getAllOrganizationAddressEntries,
  getGlobalOrganizationAddresses,
  getOrganizationUserAddresses,
};
