/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Box, debounce, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import GooglePlaceAPI from "../../api/integrations/googlePlaces";
import { Address } from "../../model/Address";

interface AddressAutocompleteProps {
  onSubmit?: (address: Address) => void;
}

export const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  onSubmit,
}) => {
  const [addresses, setAddresses] = useState<Array<Address>>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address>({
    id: "",
    first_line: "",
    second_line: "",
    state: "",
    city: "",
    zip_code: 0,
  });
  const [input, setInput] = useState<string>("");

  const addressAsString = (address: Address) => {
    if (
      address.city == "" ||
      address.first_line == "" ||
      address.state == "" ||
      address.zip_code == 0
    )
      return "";

    const secondLine =
      address.second_line && address.second_line !== ""
        ? ` ${address.second_line},`
        : "";

    return `${address.first_line},${secondLine} ${address.city}, ${address.state} ${address.zip_code}`;
  };

  useEffect(() => {
    if (input.length > 3) {
      const fetchSuggestions = debounce(async () => {
        try {
          const results =
            await GooglePlaceAPI.getAutocompleteSuggetsions(input);
          setAddresses(results);
        } catch (error) {
          console.error("Error fetching autocomplete suggestions:", error);
        }
      }, 300);

      fetchSuggestions();
    } else {
      setAddresses([]);
    }
  }, [input]);

  return (
    <Autocomplete
      value={selectedAddress}
      renderInput={(params) => (
        <TextField {...params} label="Search for Address" />
      )}
      options={addresses}
      renderOption={(props, option) => {
        return (
          <Box
            {...props}
            key={option.first_line + option.zip_code}
            component="li"
          >
            {addressAsString(option)}
          </Box>
        );
      }}
      onInputChange={(_, newValue, reason) => {
        if (reason == "clear") setInput("");
        else if (reason == "input") setInput(newValue);
        else if (reason == "reset") setInput("");
      }}
      onChange={(_, newValue) => {
        if (newValue) {
          setSelectedAddress(newValue);
          setInput(addressAsString(newValue));
          if (onSubmit) onSubmit(newValue);
        }
      }}
      getOptionLabel={(option) => {
        return addressAsString(option);
      }}
      isOptionEqualToValue={(option, value) =>
        option.first_line === value.first_line ||
        option.second_line === value.second_line ||
        option.state === value.state ||
        option.id === value.id
      }
    ></Autocomplete>
  );
};
