import {
  Badge,
  Box,
  BoxProps,
  Tab,
  TabProps,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { Organization } from "../../../model";

const OrganizationIcon = styled(Box, {
  shouldForwardProp: (prop) => prop != "organization",
})<BoxProps & { organization: Organization }>(({ theme, organization }) => {
  const commonStyles = {
    backgroundColor: "#fff",
    height: 38,
    width: 38,
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
  };
  if (organization.theme?.shortLogoUrl) {
    return {
      ...commonStyles,
      backgroundImage: `url('${organization.theme?.shortLogoUrl}')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundClip: "border-box",
      backgroundSize: "38px 38px",
      "*": {
        // hide all children elements if there is a logo
        display: "none",
      },
    };
  }
  return {
    ...commonStyles,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});

export const OrganizationTab = styled(
  ({
    organization,
    ...props
  }: {
    organization: Organization;
  } & Omit<TabProps, "label">) => {
    return (
      <Tooltip
        data-cy={"org-tab-tooltip"}
        title={organization.name}
        placement="right"
        arrow
      >
        <Tab
          {...props}
          label={
            <>
              <OrganizationIcon organization={organization}>
                <Typography>
                  {organization.name
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word.charAt(0).toUpperCase())
                    .join("")}
                </Typography>
                <Badge
                  badgeContent={organization?.unread_notifications}
                  color="error"
                  sx={{ position: "absolute", top: 12, right: 12, zIndex: 1 }}
                ></Badge>
              </OrganizationIcon>
            </>
          }
        />
      </Tooltip>
    );
  },
)(({ theme, organization }) => {
  const { organization: currentOrganization } = useGlobalOrganizationContext();
  const bgColor = theme.palette.primary.main;
  const selected = currentOrganization?.id == organization.id;
  const invertedBorder = {
    content: "''",
    width: "12px",
    height: "12px",
    position: "absolute",
    right: 0,
    boxShadow: `6px 0px 0 0 ${bgColor}`,
  };
  return {
    overflow: "visible", // required for inverted border
    opacity: 1,
    marginLeft: "6px",
    padding: "8px",
    borderTopLeftRadius: "6px",
    borderBottomLeftRadius: "6px",
    boxShadow: selected ? theme.shadows[3] : undefined,
    "&.Mui-selected": {
      backgroundColor: bgColor,
      "&::before": {
        ...invertedBorder,
        top: -12,
        borderBottomRightRadius: "6px",
      },
      "&::after": {
        ...invertedBorder,
        bottom: -12,
        borderTopRightRadius: "6px",
      },
    },
  };
});
