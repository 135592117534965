import {
  AccountBalance,
  AssuredWorkload,
  Error,
  GppMaybe,
  RemoveCircle,
  Settings,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import Organizations from "../../../api/organizations";
import PATHS from "../../../components/navigation/_paths";
import useFeature, { getCachedFeatures } from "../../../hooks/useFeature";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import useIsAdmin from "../../../hooks/useIsAdmin";
import {
  StripeCustomerStatus,
  StripeOrganizationSubscription,
} from "../../../model/OrganizationBillingState";
import messages from "../../process/messages";
import OrganizationSubscriptionCard from "../components/OrganizationSubscriptionCard";
import RequestTrackerReport from "../components/RequestTrackerReport";
import { useUpdateOrganizationProps } from "../components/UpdateOrganizationContext";

enum StripeCustomerStatusShow {
  SUBSCRIBED,
  NOT_SUBSCRIBED,
  DELETED,
  NO_ID,
  UNPAID,
  ERROR,
}

const STRIPE_SUBSCRIPTION_ICONS = {
  [StripeCustomerStatusShow.SUBSCRIBED]: (color: string) => (
    <AssuredWorkload
      fontSize="large"
      sx={{
        color: color,
      }}
    />
  ),
  [StripeCustomerStatusShow.NOT_SUBSCRIBED]: (color: string) => (
    <AccountBalance
      fontSize="large"
      sx={{
        color: color,
      }}
    />
  ),
  [StripeCustomerStatusShow.DELETED]: (color: string) => (
    <RemoveCircle
      fontSize="large"
      sx={{
        color: color,
      }}
    />
  ),
  [StripeCustomerStatusShow.NO_ID]: (color: string) => (
    <Warning
      fontSize="large"
      sx={{
        color: color,
      }}
    />
  ),
  [StripeCustomerStatusShow.ERROR]: (color: string) => (
    <Error
      fontSize="large"
      sx={{
        color: color,
      }}
    />
  ),
  [StripeCustomerStatusShow.UNPAID]: (color: string) => (
    <GppMaybe
      fontSize="large"
      sx={{
        color: color,
      }}
    />
  ),
};

const STRIPE_SUBSCRIPTION_COLORS = {
  [StripeCustomerStatusShow.SUBSCRIBED]: () => "black",
  [StripeCustomerStatusShow.NOT_SUBSCRIBED]: () => "black",
  [StripeCustomerStatusShow.DELETED]: (theme: Theme) =>
    theme.palette.error.main,
  [StripeCustomerStatusShow.NO_ID]: (theme: Theme) =>
    theme.palette.warning.main,
  [StripeCustomerStatusShow.ERROR]: (theme: Theme) => theme.palette.error.main,
  [StripeCustomerStatusShow.UNPAID]: (theme: Theme) => theme.palette.error.main,
};

const OrganizationSubscriptionSettings: FC<unknown> = () => {
  const {
    organization: sourceOrganization,
    isCreatingOrganization,
    viewSubscriptions,
  } = useUpdateOrganizationProps();

  const intl = useIntl();
  const executionMsg = intl.formatMessage(messages.execution.props);
  const executionsMsg = intl.formatMessage(messages.executions.props);
  const viewFeaturesForOrganization = useFeature("organization.features");
  const viewRequestTrackingUsage = useFeature("process.tracker");
  const canAdministrate = useIsAdmin();
  const navigate = useNavigate();

  if (!canAdministrate && !isCreatingOrganization) {
    navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
  }

  const stripeCustomerPortalId = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL;

  const [organizationSubscriptions, setOrganizationSubscriptions] = useState<
    StripeOrganizationSubscription[]
  >([]);

  const [organizationSubscriptionsLoaded, setOrganizationSubscriptionsLoaded] =
    useState<boolean>(false);

  const { organizationBillingState, organizationBillingStateLoaded } =
    useGlobalOrganizationContext();

  // State used for the organization deletion dialog
  const [organization] = useState(sourceOrganization);

  // State to hold the organization logo

  const [currentCustomer, setCurrentCustomer] =
    useState<StripeCustomerStatus>();

  const customerOweMoney = useMemo(() => {
    return currentCustomer?.balance && currentCustomer.balance > 0;
  }, [currentCustomer]);

  const stripeCustomerState = useMemo(() => {
    if (!currentCustomer) return StripeCustomerStatusShow.ERROR;
    if (!currentCustomer.id) return StripeCustomerStatusShow.NO_ID;
    if (currentCustomer.deleted) return StripeCustomerStatusShow.DELETED;
    if (currentCustomer.balance > 0) return StripeCustomerStatusShow.UNPAID;
    if (
      !currentCustomer.subscriptions ||
      currentCustomer.subscriptions.length === 0
    )
      return StripeCustomerStatusShow.NOT_SUBSCRIBED;
    return StripeCustomerStatusShow.SUBSCRIBED;
  }, [currentCustomer]);

  // handle changing an organization to fetch the current subscriptions for administrators
  useEffect(() => {
    setOrganizationSubscriptionsLoaded(false);
    if (isCreatingOrganization) return; // don't fetch subscriptions for new organizations
    const organizationId = sourceOrganization.id;
    Organizations.getSubscriptions(organizationId)
      .then((customer: StripeCustomerStatus) => {
        const subscriptions = customer.subscriptions ?? [];

        setOrganizationSubscriptions(subscriptions);
        setOrganizationSubscriptionsLoaded(true);
        setCurrentCustomer(customer);
      })
      .catch(() => {
        // err likely if no permissions
        setOrganizationSubscriptions([]);
      });
  }, [isCreatingOrganization, sourceOrganization]);

  // show all cached or organizational features that are enabled
  const organizationFeaturesToShow = useMemo(() => {
    // get the current cached features of the organization (if applicable)
    const cachedFeatures = organizationBillingState
      ? getCachedFeatures(organizationBillingState)
      : [];
    // combine the cached features with the organizational features
    const showFeatures = [];
    if (cachedFeatures) {
      showFeatures.push(...cachedFeatures);
    }
    if (organization.features) {
      showFeatures.push(...organization.features);
    }
    return showFeatures;
  }, [organizationBillingState, organization.features]);

  const theme = useTheme();

  if (organization)
    return (
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h2">
            Organization Billing and Subscription
          </Typography>
        </Grid>
        {stripeCustomerPortalId && (
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title="Stripe portal to manage your payment information">
                <Chip
                  label="Manage on Stripe"
                  deleteIcon={<Settings />}
                  onDelete={() => {
                    /* */
                  }}
                  target="_blank"
                  component="a"
                  href={`https://billing.stripe.com/p/login/${stripeCustomerPortalId}?prefilled_email=${encodeURIComponent(
                    organization.billing_email || "",
                  )}`}
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            </Box>
          </Grid>
        )}
        {organizationSubscriptionsLoaded ? (
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  {STRIPE_SUBSCRIPTION_ICONS[stripeCustomerState](
                    STRIPE_SUBSCRIPTION_COLORS[stripeCustomerState](theme) ||
                      "black",
                  )}

                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="h5"
                      fontWeight={"bold"}
                      sx={{
                        color:
                          STRIPE_SUBSCRIPTION_COLORS[stripeCustomerState](
                            theme,
                          ) || "black",
                      }}
                    >
                      {stripeCustomerState ===
                        StripeCustomerStatusShow.SUBSCRIBED ||
                      stripeCustomerState ===
                        StripeCustomerStatusShow.NOT_SUBSCRIBED ||
                      stripeCustomerState ===
                        StripeCustomerStatusShow.DELETED ||
                      stripeCustomerState === StripeCustomerStatusShow.UNPAID
                        ? currentCustomer?.name
                        : stripeCustomerState === StripeCustomerStatusShow.NO_ID
                          ? "You're Not Subscribed!"
                          : "Error"}
                    </Typography>
                    <Typography variant="body2">
                      {currentCustomer?.email}
                    </Typography>
                    <Typography variant="body2">
                      {currentCustomer?.phone}
                    </Typography>
                  </Box>
                </Box>

                {stripeCustomerState === StripeCustomerStatusShow.NO_ID && (
                  <Typography
                    variant="caption"
                    maxWidth={400}
                    textAlign={"right"}
                    sx={{
                      color:
                        STRIPE_SUBSCRIPTION_COLORS[stripeCustomerState](theme),
                    }}
                  >
                    Your organization may not be subscribed to Runway, and may
                    not have access to all of Runway&apos;s features!
                  </Typography>
                )}

                {(stripeCustomerState === StripeCustomerStatusShow.SUBSCRIBED ||
                  stripeCustomerState ===
                    StripeCustomerStatusShow.NOT_SUBSCRIBED ||
                  stripeCustomerState === StripeCustomerStatusShow.UNPAID) && (
                  <Tooltip
                    title={
                      customerOweMoney
                        ? "This organization has an outstanding balance. Please add a payment method now!"
                        : "This organization has a credit balance"
                    }
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"column"}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          color: (theme) =>
                            customerOweMoney
                              ? theme.palette.error.main
                              : theme.palette.success.main,
                        }}
                        fontWeight={"bold"}
                      >
                        $
                        {(
                          Math.abs(currentCustomer?.balance || 0) / 100
                        ).toFixed(2)}
                      </Typography>
                      <Typography variant="caption">
                        {customerOweMoney ? "outstanding" : "in credit"}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Box>
              {viewSubscriptions &&
                ((organizationSubscriptions &&
                  organizationSubscriptions.length > 0) ||
                  !organizationSubscriptionsLoaded) && (
                  <>
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    />
                    <Box>
                      <Typography variant="h6" fontWeight={"bold"}>
                        Subscriptions
                      </Typography>
                      {organizationSubscriptions?.map((subscription) => (
                        <OrganizationSubscriptionCard
                          key={subscription.id}
                          subscription={subscription}
                        />
                      ))}
                    </Box>
                  </>
                )}
              {viewSubscriptions &&
                organizationSubscriptionsLoaded &&
                organizationSubscriptions.length === 0 && (
                  <>
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    />
                    <Typography
                      variant="body1"
                      textAlign={"center"}
                      fontStyle={"italic"}
                    >
                      No active subscriptions
                    </Typography>
                  </>
                )}
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}

        {viewFeaturesForOrganization && viewSubscriptions && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">Features</Typography>
              <Typography variant="caption">
                These are the active features for this organization provided
                through your current subscriptions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!organizationBillingStateLoaded && (
                <CircularProgress size={20} />
              )}
              {organizationBillingStateLoaded &&
                organizationFeaturesToShow.length === 0 && (
                  <Typography variant="body1">No features enabled</Typography>
                )}
              {organizationBillingStateLoaded &&
                organizationFeaturesToShow.map((feature) => (
                  <Chip
                    key={feature}
                    label={feature}
                    sx={{
                      marginRight: 1,
                      marginBottom: 1,
                    }}
                  />
                ))}
            </Grid>
          </>
        )}

        {viewRequestTrackingUsage && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">{executionMsg} Tracking</Typography>
              <Typography variant="caption">
                This is the current status of all of the {executionsMsg} that
                have been tracked. You are only billed for {executionsMsg} that
                have been completed.
              </Typography>
              <RequestTrackerReport organization={organization} />
            </Grid>
          </>
        )}
      </Grid>
    );

  /*  return (
      <>
        <Grid item xs={8}>
          <Typography variant="h2">Organization</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Publicly accessible link to public portal for this organization">
              <Chip
                label="Portal"
                deleteIcon={<Launch />}
                onDelete={() => {
                }}
                target="_blank"
                component="a"
                href={`/${organization.key}`}
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={"Name"}
            value={organization.name}
            disabled={disabled}
            onChange={(e) => {
              setOrganization((org: Organization) => ({
                ...org,
                name: e.target.value,
                key: keyEntered ? organization.key : keyify(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={"Organization Handle"}
            value={organization.key}
            disabled={disabled}
            onChange={(e) => {
              setKeyEntered(true);
              setOrganization((org) => ({ ...org, key: e.target.value }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="A unique, human-readable handle for your organization.  This will be used to create your unique URL. The handle cannot be changed.">
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: !!organization?.id,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Description"}
            size="small"
            disabled={disabled}
            value={organization.description ?? ""}
            onChange={(e) =>
              setOrganization(
                (org) =>
                  ({
                    ...org,
                    description: e.target.value,
                  }) as Organization,
              )
            }
          />
        </Grid>
        {!emailDisabled && (
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label={"Email"}
              size="small"
              disabled={disabled}
              value={organization.email ?? ""}
              onChange={(e) =>
                setOrganization((org) => ({ ...org, email: e.target.value }))
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label={"Billing Email"}
            size="small"
            disabled={!isCreatingOrganization}
            value={organization.billing_email ?? ""}
            onChange={(e) =>
              setOrganization(
                (org) =>
                  ({
                    ...org,
                    billing_email: e.target.value,
                  }) as Organization,
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              my: 4,
              display: "flex",
              justifyContent: buttonAlignment === "right" ? "right" : "left",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleSubmit}
              >
                {buttonText ?? "Submit"}
              </Button>
            )}
          </Box>
        </Grid>
        {error && (
          <Box sx={{ my: 2 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
      </>
    );*/
};

export { OrganizationSubscriptionSettings };
