import {
  Box,
  Button,
  Container,
  Divider,
  List,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Organizations from "../../api/organizations";
import PATHS from "../../components/navigation/_paths";
//import { useNavigate } from "react-router";
import {
  AccountBalanceWallet,
  ArrowForwardIos,
  CorporateFareRounded,
  Settings,
} from "@mui/icons-material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/layout/page";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../lib/alert";
import { Organization } from "../../model";
import NotFound from "../app/NotFound";
import {
  OrganizationContextProps,
  UpdateOrganizationContext,
} from "./components/UpdateOrganizationContext";
//import { useAlert } from "../../lib/alert";
//import NotFound from "../app/NotFound";

interface MenuButtonProps {
  children: React.ReactNode;
  page: string;
  clickEvent?: (num: number) => void;
}
const MenuButton: FC<MenuButtonProps & React.ComponentProps<typeof Button>> = ({
  children,
  page,
  ...props
}) => {
  const buttonStyles = {
    display: "flex",
    borderRadius: 0,
    justifyContent: "space-between", // Pushes content to the edges
    alignItems: "center",
    textTransform: "none",
    fontSize: "0.875rem",
    width: "100%",
    px: 2,
    py: 2,
  };
  const [isActive, setIsActive] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsActive(location.pathname.includes(page));
  }, [location, page]);

  return (
    <Link to={page} style={{ textDecoration: "none", color: "inherit" }}>
      <Button
        fullWidth
        startIcon={<Settings />}
        endIcon={<ArrowForwardIos />}
        color="inherit"
        sx={
          isActive
            ? { backgroundColor: "#f2f2f2", ...buttonStyles }
            : { ...buttonStyles }
        }
        {...props}
      >
        <Typography
          sx={{
            width: "100%",
            alignSelf: "flex-start",
            textAlign: "left",
          }}
        >
          {children}
        </Typography>
      </Button>
    </Link>
  );
};

const UpdateOrganizationPage: FC<unknown> = () => {
  const { success } = useAlert();
  const navigate = useNavigate();
  const { organization, setOrganization, refreshOrganizations } =
    useGlobalOrganizationContext();

  if (!organization) {
    return <NotFound />;
  }

  const UpdateOrganizationContextValue: OrganizationContextProps = {
    organization: organization,
    onOrganizationDeleted: () => {
      refreshOrganizations().then(() => {
        navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link);
      });
    },
    setOrganization: setOrganization,
    refreshOrganizations: refreshOrganizations,
    onSubmit: async (organization: Organization) => {
      const updatedOrganization = await Organizations.update(organization);
      refreshOrganizations();
      setOrganization(updatedOrganization);
      success("Successfully updated organization");
    },
    disabled: false,
    buttonAlignment: undefined,
  };

  //if (organization) {
  return (
    <PageContainer variant="full">
      <PageHeader title="Manage Organization" />
      <PageContent>
        <Container maxWidth="xl" sx={{ p: 2 }}>
          <Box display="flex" sx={{ flexDirection: "row" }}>
            <List
              sx={{
                minWidth: 350,
                flexDirection: "column",
              }}
            >
              {/* General Settings */}
              <Divider
                variant="inset"
                component="li"
                sx={{ alignSelf: "center", margin: "auto" }}
              />
              <MenuButton page="general">General Settings</MenuButton>

              {/* Subscription Management */}
              <Divider
                variant="inset"
                component="li"
                sx={{ alignSelf: "center", margin: "auto" }}
              />

              <MenuButton
                page="subscription"
                startIcon={<AccountBalanceWallet />}
              >
                Manage Subscription
              </MenuButton>

              {/* Organization Addresses */}
              <Divider
                variant="inset"
                component="li"
                sx={{ alignSelf: "center", margin: "auto" }}
              />

              <MenuButton page="addresses" startIcon={<CorporateFareRounded />}>
                Organization Addresses
              </MenuButton>

              <Divider
                variant="inset"
                component="li"
                sx={{ alignSelf: "center", margin: "auto" }}
              />
            </List>

            <Box flexGrow={1} sx={{ px: 5 }}>
              <UpdateOrganizationContext.Provider
                value={UpdateOrganizationContextValue}
              >
                <Outlet />
              </UpdateOrganizationContext.Provider>
            </Box>
          </Box>
          {/*<UpdateOrganization
              organization={organization}
              onSubmit={async (organization: Organization) => {
                const updatedOrganization =
                  await Organizations.update(organization);
                refreshOrganizations();
                setOrganization(updatedOrganization);
                success("Successfully updated organization");
              }}
              onOrganizationDeleted={() => {
                refreshOrganizations().then(() => {
                  navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link);
                });
              }}
              isCreatingOrganization={false}
              viewSubscriptions={true}
            />*/}
        </Container>
      </PageContent>
    </PageContainer>
  );
  //}
  //return <NotFound />;
};

export default UpdateOrganizationPage;
